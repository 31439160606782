export const DESCRIPTION_STEP_ONE =
  "We'll help you choose from energy, broadband, mobile and insurance services to create a bundle that's right for you";

export const DESCRIPTION_STEP_TWO = 'So we can customise your services.';

export const DESCRIPTION_STEP_THREE =
  'We will contact your existing suppliers and manage the transfer of your services - you can sit back and relax!';

export const LABEL_STEP_ONE = 'Step 1';

export const LABEL_STEP_TWO = 'Step 2';

export const LABEL_STEP_THREE = 'Step 3';

export const TITLE = 'Three simple steps to big savings';

export const TITLE_STEP_ONE = 'Choose services to add to your one simple bill';

export const TITLE_STEP_TWO = 'Tell us a few details';

export const TITLE_STEP_THREE = 'We will take care of the rest';
