import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

import SectionCentered from 'modules/layout/SectionCentered';
import useStyles from './styles';

const ErrorPage = ({
  classes = {},
  showImage = true,
  message = 'Sorry, something went wrong.',
}) => {
  const internalClasses = useStyles();

  return (
    <SectionCentered
      sectionCenteredClasses={{
        root: classNames(internalClasses.root, classes.root),
        container: classNames(internalClasses.container, classes.container),
      }}
    >
      <Typography color="primary" variant="h1" gutterBottom>
        Oops!
      </Typography>
      <Typography color="primary" variant="h2" gutterBottom>
        {message}
      </Typography>
      <Typography color="primary" gutterBottom>
        If reloading this page doesn't fix the issue, please contact our
        support.
      </Typography>
      {showImage && (
        <div className={internalClasses.imageContainer}>
          <img
            src="https://a0.muscache.com/airbnb/static/error_pages/404-Airbnb_final-d652ff855b1335dd3eedc3baa8dc8b69.gif"
            alt="error image"
          />
        </div>
      )}
      <Button
        classes={{ root: classNames(internalClasses.button, classes.button) }}
        variant="contained"
        onClick={() => window.location.reload()}
      >
        Reload
      </Button>
    </SectionCentered>
  );
};

ErrorPage.propTypes = {
  classes: PropTypes.object,
  showImage: PropTypes.bool,
  message: PropTypes.string,
};

export default ErrorPage;
