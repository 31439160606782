import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  heading: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headingTitle: {
    fontSize: 24,
    marginBottom: theme.spacing(3),
  },
  headingCopy: {
    maxWidth: '50ch',
  },
  confirm: {
    marginTop: theme.spacing(3),
    alignItems: 'flex-start',
  },
  dialog: {
    maxWidth: 600,
  },
  dialogContent: {
    width: '100%',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    rowGap: theme.spacing(2),
  },
  successGfx: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
  },
  requestStatus: {
    marginTop: theme.spacing(3),
  },
});

export default makeStyles(styles);
