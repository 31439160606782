import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import HomeInsuranceQuote from './views/Quote';
import { useNavigation, screens } from './context/navigation';
import AboutProperty from './views/AboutProperty';
import PrevClaims from './views/PrevClaims';
import AdditionalCover from './views/AdditionalCover';
import ValuableItems from './views/ValuableItems';

const HomeInsurance = ({ ...props }) => {
  const { activeScreen } = useNavigation();
  const { quote, getHomeInsuranceQuoteRequest } = props;

  useEffect(() => {
    getHomeInsuranceQuoteRequest();
  }, []);

  if (activeScreen && activeScreen === screens.aboutProperty) {
    return <AboutProperty />;
  }
  if (activeScreen && activeScreen === screens.prevClaims) {
    return <PrevClaims {...props} />;
  }
  if (activeScreen && activeScreen === screens.addCover) {
    return <AdditionalCover {...props} />;
  }
  if (activeScreen && activeScreen === screens.valuableItems) {
    return <ValuableItems {...props} />;
  }

  return <HomeInsuranceQuote quote={quote} />;
};
HomeInsurance.propTypes = {
  quote: PropTypes.any,
  updateQuoteField: PropTypes.func,
  addSpecifiedItem: PropTypes.func,
  updateSpecifiedItem: PropTypes.func,
  removeSpecifiedItem: PropTypes.func,
  addBicycleItem: PropTypes.func,
  updateBicycleItem: PropTypes.func,
  removeBicycleItem: PropTypes.func,
  addClaim: PropTypes.func,
  updateClaim: PropTypes.func,
  removeClaim: PropTypes.func,
  getHomeInsuranceQuoteRequest: PropTypes.func,
};
export default HomeInsurance;
