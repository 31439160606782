import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Typography, Button, Box, Card } from '@material-ui/core';
import ContentDropdown from 'modules/Shared/ContentDropdown';
import useStyles from './styles';
import Check from '@utilitywarehouse/partner-ui-icons/lib/Check';
import Add from '@utilitywarehouse/partner-ui-icons/lib/Add';

const ServiceCard = ({ service, onSelect, selected }) => {
  const classes = useStyles();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const { title, price, description, covered, notCovered } = service;
  return (
    <Card className={classes.card}>
      <Typography variant="h4">{title}</Typography>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '5px',
          alignItems: 'flex-end',
        }}
      >
        <Typography variant="h4">{price}</Typography>
        <Typography variant="legalNote"> a month</Typography>
      </Box>
      <Typography variant="body1">{description}</Typography>
      <Button
        variant={selected ? 'contained' : 'outlined'}
        fullWidth
        className={classes.select}
        size="medium"
        onClick={onSelect}
        startIcon={selected ? <Check /> : <Add />}
      >
        {selected ? 'Cover Added' : 'Add cover'}
      </Button>
      <ContentDropdown
        fullWidth
        open={detailsOpen}
        onOpen={() => setDetailsOpen(!detailsOpen)}
        label={
          <Typography variant="h5" component="h5">
            What's covered
          </Typography>
        }
      >
        <Typography variant="caption">
          <b>Examples of accidents that are covered</b>
        </Typography>
        <ul style={{ listStyleType: 'none', paddingLeft: '0px' }}>
          {covered.map((el) => (
            <li key={el}>{el}</li>
          ))}
        </ul>
        <Typography variant="caption">
          <b>Examples of accidents that are not covered</b>
        </Typography>
        <ul style={{ listStyleType: 'none', paddingLeft: '0px' }}>
          {notCovered.map((el) => (
            <li key={el}>{el}</li>
          ))}
        </ul>
      </ContentDropdown>
    </Card>
  );
};
ServiceCard.propTypes = {
  onSelect: PropTypes.func.isRequired,
  service: PropTypes.any,
  selected: PropTypes.bool.isRequired,
};
export default ServiceCard;
