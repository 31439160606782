import {
  getErrorPaletteColor,
  getSpacing,
  getSuccessPaletteColor,
} from 'app/lib/theme';

export default (theme) => {
  const errorColor = getErrorPaletteColor('dark')(theme);
  const successColor = getSuccessPaletteColor('dark')(theme);
  const spacing = getSpacing(theme);

  return {
    container: {
      marginBottom: spacing(4),
      overflow: 'visible',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: spacing(4),
    },
    formRow: {
      marginBottom: spacing(2),
    },
    submit: {
      alignSelf: 'flex-end',
    },
    error: {
      color: errorColor,
    },
    submitted: {
      color: successColor,
    },
  };
};
