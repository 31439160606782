import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import Space from 'components/modules/Shared/Space';
import styles from './styles.js';

const YesNoButtonCombo = ({
  onYes,
  onNo,
  expectedYesValue,
  expectedNoValue,
  valueSelected,
  yesDataTestId,
  noDataTestId,
  classes,
}) => {
  return (
    <div className={classes.accordionYesNo}>
      <Button
        size="large"
        variant={valueSelected === expectedYesValue ? 'contained' : 'outlined'}
        onClick={() => onYes()}
        data-test-id={yesDataTestId}
        classes={{ root: classes.option }}
      >
        Yes
      </Button>
      <Space width="20px" />
      <Button
        size="large"
        variant={valueSelected === expectedNoValue ? 'contained' : 'outlined'}
        onClick={() => onNo()}
        data-test-id={noDataTestId}
        classes={{ root: classes.option }}
      >
        No
      </Button>
    </div>
  );
};

YesNoButtonCombo.propTypes = {
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
  expectedYesValue: PropTypes.any.isRequired,
  expectedNoValue: PropTypes.any.isRequired,
  valueSelected: PropTypes.any,
  yesDataTestId: PropTypes.string,
  noDataTestId: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(YesNoButtonCombo);
