import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { Alert } from 'modules/Shared/Alert';
import TextField from 'modules/CustomerUI/TextField';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';
import {
  validateGBMobileNumber,
  validateGBLandlineNumber,
} from 'redux/utils/validateGBNumber';
import useStyles from './styles';
import { FIELDS } from 'redux/modules/LeadCapture/constants';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';

const validate = (name, values) => {
  const errors = {};
  const isValidValue = !values[name] || values[name].length === 0;
  if (name === FIELDS.FIELD_NAME_FIRST) {
    if (isValidValue) {
      errors[FIELDS.FIELD_NAME] = 'Name is required';
    }
  }
  if (name === FIELDS.FIELD_NAME_LAST) {
    if (isValidValue) {
      errors[FIELDS.FIELD_NAME_LAST] = 'Name is required';
    }
  }

  if (name === FIELDS.FIELD_CONTACT) {
    if (isValidValue) {
      errors[FIELDS.FIELD_CONTACT] = 'Contact number is required';
    } else if (
      !validateGBMobileNumber(values[name]) &&
      !validateGBLandlineNumber(values[name])
    ) {
      errors[FIELDS.FIELD_CONTACT] = 'Invalid contact number';
    }
  }

  return errors;
};

const LeadCapture = ({
  onSubmit,
  onClose,
  onSetValue,
  onReset,
  error,
  values,
  partnerName,
  submitSuccess,
  isDisabled = false,
}) => {
  const classes = useStyles();
  const [successLoader, setSuccessLoader] = useState(false);
  const [successCompleted, setSuccessCompleted] = useState(false);
  const [errors, setErrors] = useState({});

  const tracking = useTracking();
  useEffect(() => {
    if (submitSuccess && !successLoader && !successCompleted) {
      setSuccessLoader(true);

      setTimeout(() => {
        setSuccessLoader(false);
        setSuccessCompleted(true);
        onReset();
      }, 2000);
    }
  }, [successLoader, submitSuccess, setSuccessLoader, successCompleted]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      tracking.track(EVENTS.RAF_LEAD_CAPTURE_FORM_SUBMITTED);
      onSubmit();
      setSuccessCompleted(false);
    },
    [onSubmit, tracking]
  );

  const handleFieldChange = useCallback(
    (e, value) => {
      const name = e.target.name;
      const updatedValues = {
        ...values,
        [name]: value,
      };
      const errors = validate(name, updatedValues);
      setErrors(errors);
      onSetValue(name, value);
    },
    [onSetValue, values]
  );

  const getFieldProps = (name) => {
    return {
      name,
      onChange: handleFieldChange,
      value: values[name],
      error: !!errors[name],
      errorMessage: errors[name],
    };
  };

  if (successLoader) {
    return (
      <div className={classes.successGfx}>
        <img src="/images/thankyou/tick-non-looping.gif" alt="" />
        <Typography variant="h3" className={classes.headingTitle}>
          Details saved
        </Typography>
      </div>
    );
  }

  const valid = Object.keys(errors).length === 0;

  return (
    <form onSubmit={handleSubmit}>
      <heading className={classes.heading}>
        <Typography variant="h3" className={classes.headingTitle}>
          Share your friend&rsquo;s details
        </Typography>
        <Typography className={classes.headingCopy}>
          {partnerName} will contact them about UW services and help them save.
          If they&rsquo;re a homeowner and switch two or more services,
          you&rsquo;ll both get £50 in credit.
        </Typography>
      </heading>
      {submitSuccess && (
        <Alert severity="success" className={classes.requestStatus}>
          Friend saved in {partnerName}’s UW Partner app! Add as many more as
          you like...
        </Alert>
      )}
      {error && (
        <Alert severity="error" className={classes.requestStatus}>
          {error}
        </Alert>
      )}
      <TextField
        required
        label="First name"
        fullWidth
        {...getFieldProps('leadCaptureNameFirst')}
      />
      <TextField
        required
        label="Surname"
        fullWidth
        {...getFieldProps('leadCaptureNameLast')}
      />
      <TextField
        required
        label="Contact number"
        fullWidth
        {...getFieldProps('leadCaptureContact')}
      />
      <FormControlLabel
        name="leadCaptureConfirmed"
        value={values[FIELDS.FIELD_CONFIRM]}
        onChange={(_, value) => onSetValue(FIELDS.FIELD_CONFIRM, value)}
        label={`My friend has confirmed I can share their details with ${partnerName} and they are expecting ${partnerName} to get in touch to help them save money. *`}
        className={classes.confirm}
        control={
          <Checkbox
            color={values[FIELDS.FIELD_CONFIRM] ? 'secondary' : 'default'}
          />
        }
      />
      <div className={classes.actions}>
        <RestrictedButton
          variant="contained"
          size="large"
          onClick={handleSubmit}
          disabled={!valid || !values[FIELDS.FIELD_CONFIRM] || isDisabled}
          label="Share details"
          type="submit"
        />
        <RestrictedButton
          variant="text"
          size="large"
          onClick={onClose}
          label="Close"
          color="default"
        />
      </div>
    </form>
  );
};

LeadCapture.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSetValue: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitSuccess: PropTypes.bool,
  values: PropTypes.shape({
    [FIELDS.FIELD_NAME_FIRST]: PropTypes.string,
    [FIELDS.FIELD_NAME_LAST]: PropTypes.string,
    [FIELDS.FIELD_CONTACT]: PropTypes.string,
    [FIELDS.FIELD_CONFIRM]: PropTypes.bool,
  }),
  partnerName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default LeadCapture;
