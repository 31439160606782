import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { AddIcon } from '@utilitywarehouse/partner-ui-icons';
import BicyclesCard from './BicyclesCard';
import useStyles from '../../../styles';

const Bicycles = ({
  bicyclesList,
  addBicycleItem,
  updateBicycleItem,
  removeBicycleItem,
  requirementType,
}) => {
  const [isNewItem, setNewItem] = useState(false);
  const classes = useStyles();

  const handleCloseNewItem = useCallback(() => setNewItem(false), []);

  return (
    <>
      <Typography variant="h3">Add cover for bicycles</Typography>
      <Typography variant="body1">
        If you require cover for a bicycle over £750 in the home or away from
        the home, please add details.
      </Typography>
      {bicyclesList?.map((item, index) => (
        <BicyclesCard
          key={item.id}
          index={index}
          requirementType={requirementType}
          item={item}
          handleUpdate={updateBicycleItem}
          removeBicycleItem={removeBicycleItem}
          handleCloseNewItem={handleCloseNewItem}
        />
      ))}
      {isNewItem && (
        <BicyclesCard
          isNewItem
          requirementType={requirementType}
          handleUpdate={updateBicycleItem}
          removeBicycleItem={removeBicycleItem}
          addBicycleItem={addBicycleItem}
          handleCloseNewItem={handleCloseNewItem}
        />
      )}
      <Button
        variant="outlined"
        className={classes.select}
        size="medium"
        startIcon={<AddIcon fontSize="small" />}
        onClick={() => setNewItem(true)}
        disabled={isNewItem}
      >
        Add Bicycle
      </Button>
    </>
  );
};

Bicycles.propTypes = {
  bicyclesList: PropTypes.any,
  addBicycleItem: PropTypes.func,
  updateBicycleItem: PropTypes.func,
  removeBicycleItem: PropTypes.func,
  requirementType: PropTypes.any,
};

export default Bicycles;
