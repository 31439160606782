import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@material-ui/core';
import LeadCaptureContainer from 'app/redux/modules/LeadCapture/container';
import useStyles from './styles';

const LeadCaptureDialog = ({ open, onClose, isDisabled }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogContent className={classes.dialogContent}>
        <LeadCaptureContainer isDisabled={isDisabled} />
      </DialogContent>
    </Dialog>
  );
};

LeadCaptureDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default LeadCaptureDialog;
