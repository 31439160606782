import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Card } from '@material-ui/core';

import useStyles from '../../styles';
import ItemEditForm from './ItemEditForm';
import ItemDisplay from './ItemDisplay';

const newItem = {
  type: '',
  value: 0,
  date: new Date(),
  isLiving: true,
  status: 'settled',
};

const useItemState = (initialItem, isNewItem) => {
  const [itemState, setItemState] = useState(initialItem);
  useEffect(() => {
    if (isNewItem) {
      setItemState(newItem);
    }
  }, [isNewItem]);
  return [itemState, setItemState];
};

const ClaimsCard = ({
  item,
  index,
  requirementType,
  onUpdate,
  onRemove,
  onAdd,
  onClose,
  isNewItem = false,
}) => {
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = useState(false);
  const [itemState, setItemState] = useItemState(item || newItem, isNewItem);

  const handleEdit = () => setIsEditMode(true);

  const handleUpdateItem = (key, value) =>
    setItemState((prev) => ({ ...prev, [key]: value }));

  const handleSaveItem = () => {
    isNewItem
      ? onAdd(requirementType, itemState)
      : onUpdate(requirementType, index, itemState);
    onClose();
    setIsEditMode(false);
  };

  const handleRemove = () => {
    !isNewItem && onRemove(requirementType, index);
    onClose();
    setIsEditMode(false);
  };

  if (isEditMode || isNewItem) {
    return (
      <Card className={classes.card}>
        <ItemEditForm
          itemState={itemState}
          onItemChange={handleUpdateItem}
          onSave={handleSaveItem}
          onRemove={handleRemove}
        />
      </Card>
    );
  }

  return <ItemDisplay item={itemState} handleEdit={handleEdit} />;
};

ClaimsCard.propTypes = {
  requirementType: PropTypes.any,
  index: PropTypes.number,
  item: PropTypes.shape({
    type: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    date: PropTypes.date,
    isLiving: PropTypes.bool,
    status: PropTypes.string,
  }),
  onUpdate: PropTypes.func,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  isNewItem: PropTypes.bool,
  onRemove: PropTypes.func,
};

export default ClaimsCard;
