import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';
import styles from './styles';
import { TITLE, UNABLE_TO_DETERMINE_ELIGIBILITY } from './copy';

const Unknown = ({ classes }) => (
  <Card className={classes.container} variant="outlined">
    <CardContent className={classes.content}>
      <Typography component="h3" paragraph variant="h3">
        {TITLE}
      </Typography>
      <Typography paragraph>{UNABLE_TO_DETERMINE_ELIGIBILITY}</Typography>
    </CardContent>
  </Card>
);

Unknown.defaultProps = {
  classes: {},
};

Unknown.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Unknown);
