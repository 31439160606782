import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SectionCentered from 'modules/layout/SectionCentered';
import styles from './styles';

class VideoTour extends React.Component {
  constructor(props) {
    super(props);
    this.onOpenVideo = this.onOpenVideo.bind(this);
  }

  onOpenVideo() {
    this.props.toggleHeroVideo();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.base}>
        <SectionCentered>
          <div className={classes.display}>
            <div className={classes.content}>
              <div>
                <Typography
                  className={classes.quote}
                  color="primary"
                  component="p"
                  paragraph
                  variant="h1"
                >
                  All sorts
                  <br />
                  of people can
                  <br />
                  make UW work
                  <br />
                  for them
                  <br />
                </Typography>
                <Typography
                  className={classes.fogleQuote}
                  color="primary"
                  component="p"
                  paragraph
                  variant="h3"
                >
                  Ben Fogle
                </Typography>
                <Typography
                  className={classes.fogleRole}
                  color="primary"
                  component="p"
                  paragraph
                  variant="body1"
                >
                  Adventurer, brand ambassador
                  <br />
                  and UW customer
                </Typography>
              </div>
            </div>
          </div>
        </SectionCentered>
      </div>
    );
  }
}

VideoTour.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleHeroVideo: PropTypes.func.isRequired,
};

export default withStyles(styles)(VideoTour);
