import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import TitleBar from 'modules/Shared/TitleBar';
import { useStyles } from './styles';

const SessionDialog = ({ isRemoteSession, action }) => {
  const classes = useStyles();

  return (
    <Dialog open fullWidth>
      <DialogTitle>
        <TitleBar>
          <b>Existing Session</b>
        </TitleBar>
      </DialogTitle>
      <DialogContent>
        <Typography>
          You are currently part way through an application.
          {!isRemoteSession && (
            <b>Would you like to continue or start again?</b>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            onClick={() => action(false)}
            data-test-id="ExistingSessionStartAgain"
          >
            Start Again
          </Button>
          <Button
            variant="contained"
            onClick={() => action(true)}
            data-test-id="ExistingSessionContinue"
            disabled={isRemoteSession}
            classes={{ root: classes.button }}
          >
            Continue
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

SessionDialog.propTypes = {
  isRemoteSession: PropTypes.bool.isRequired,
  action: PropTypes.func,
};

export default SessionDialog;
