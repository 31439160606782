import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const NavigationContext = createContext();

export const useNavigation = () => {
  return useContext(NavigationContext);
};

export const screens = {
  quote: 'QUOTE',
  aboutProperty: 'ABOUT_PROPERTY',
  prevClaims: 'PREV_CLAIMS',
  addCover: 'ADDITIONAL_COVER',
  valuableItems: 'VALUABLE_ITEMS',
};

export const NavigationProvider = ({ children }) => {
  const [activeScreen, setActiveScreen] = useState(screens.quote);
  const navigateToScreen = (screenName) => {
    setActiveScreen(screenName);
  };

  const backToQuote = () => {
    setActiveScreen(screens.quote);
  };

  const value = {
    activeScreen,
    backToQuote,
    navigateToScreen,
  };

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

NavigationProvider.propTypes = {
  children: PropTypes.any,
};
