import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {
  Typography,
  Box,
  Card,
  Link,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import SectionCentered from 'modules/layout/SectionCentered';
import Check from '@utilitywarehouse/partner-ui-icons/lib/Check';

import useStyles from '../styles';
import EditButton from '../components/EditButton';
import { homeDisclosures, personalDisclosures } from '../testConts';
import { useNavigation, screens } from '../context/navigation';
import ExpansionRow from '../components/ExpansionRow';
import { renderCoverage } from '../utils/renderCoverage';

const HomeInsuranceQuote = ({ quote }) => {
  const classes = useStyles();
  const { navigateToScreen } = useNavigation();
  const {
    charges,
    product: {
      name,
      details: {
        documents,
        escapeOfWaterExcess,
        standardExcess,
        subsidenceExcess,
      },
    },
    requirements,
  } = quote || {};
  const { amount } = charges[0];
  const {
    requirement: { buildingsAccidentalDamage, contentsAccidentalDamage },
  } = requirements[0];
  const {
    requirement: { bicyclesList, specifiedItemsList, unspecifiedItemsAmount },
  } = requirements[1];
  const {
    requirement: { claimsList },
  } = requirements[2];

  const hasBicycles = bicyclesList && bicyclesList.length > 0;
  const requireUnspecCover = unspecifiedItemsAmount > 0;
  const requireSpecCover = specifiedItemsList?.length > 0;

  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleToggleConfirmation = () => {
    setIsConfirmed((prev) => !prev);
  };

  return (
    <>
      <SectionCentered
        backgroundColor="grey"
        newLayout
        sectionCenteredClasses={{ root: classes.newMainContent }}
      >
        <header className={classes.header}>
          <div className={classes.headerCopy}>
            <Typography variant="h2" color="primary" gutterBottom>
              Your Quote
            </Typography>
            <Typography className={classes.subTitle}>
              Save more money and time by switching to our 5 star Defaqto-rated
              home insurance.
            </Typography>
          </div>
        </header>
        <main className={classes.mainContent}>
          <Card className={classes.card}>
            <Typography variant="h3">{name}</Typography>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'flex-end',
              }}
            >
              <Typography variant="h4">£{amount.value}</Typography>
              <Typography variant="legalNote"> a month</Typography>
            </Box>
            <Typography variant="body1">
              Covers the structure of your home, as well as what's inside.
            </Typography>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'flex-end',
              }}
            >
              <Check className={classes.checkIcon} />
              <Typography variant="body1">
                Pay monthly at no extra cost
              </Typography>
            </Box>
            <ExpansionRow
              escapeOfWaterExcess={escapeOfWaterExcess}
              standardExcess={standardExcess}
              subsidenceExcess={subsidenceExcess}
            />
          </Card>
          <Card className={classes.card}>
            <div className={classes.cardHeading}>
              <Typography variant="h4">Previous Claims</Typography>
              <EditButton
                text="Edit"
                onClick={() => navigateToScreen(screens.prevClaims)}
              />
            </div>

            <Typography variant="body1">
              {claimsList?.length > 0
                ? 'I have made house insurance claims, or had claims made against me, within the last 5 years.'
                : 'I have not made any house insurance claims, or had claims made against me, within the last 5 years.'}
            </Typography>
          </Card>
          <Card className={classes.card}>
            <div className={classes.cardHeading}>
              <Typography variant="h4">Additional Cover</Typography>
              <EditButton
                text="Edit"
                onClick={() => navigateToScreen(screens.addCover)}
              />
            </div>
            <Typography variant="body1">
              {renderCoverage(buildingsAccidentalDamage)} require{' '}
              <b>accidential damage cover</b> for my buildings.
            </Typography>
            <Typography variant="body1">
              {renderCoverage(contentsAccidentalDamage)} require{' '}
              <b>accidential damage cover</b> for my contents.
            </Typography>
          </Card>
          <Card className={classes.card}>
            <div className={classes.cardHeading}>
              <Typography variant="h4">Valuable Items</Typography>
              <EditButton
                text="Edit"
                onClick={() => navigateToScreen(screens.valuableItems)}
              />
            </div>
            <Typography variant="body1">
              {renderCoverage(requireSpecCover)} require cover for{' '}
              <b>valuable items</b>.
            </Typography>
            <Typography variant="body1">
              {renderCoverage(requireUnspecCover)} need{' '}
              <b>unspecified items cover</b>.
            </Typography>
            <Typography variant="body1">
              {renderCoverage(hasBicycles)} require cover for <b>bicycles</b>.
            </Typography>
          </Card>
        </main>
      </SectionCentered>
      <SectionCentered
        backgroundColor="white"
        newLayout
        sectionCenteredClasses={{ root: classes.newMainContent }}
      >
        <main className={classes.mainContent} style={{ paddingTop: '20px' }}>
          <Typography variant="h3">Disclosures</Typography>
          <Typography variant="h5">The home you plann to insure:</Typography>
          <ol className={classes.list}>
            {homeDisclosures.map((e) => (
              <li key={e}>{e}</li>
            ))}
          </ol>
          <Typography variant="h5">
            You & anyone else living in the house has
          </Typography>
          <ol className={classes.list}>
            {personalDisclosures.map((e) => (
              <li key={e}>{e}</li>
            ))}
          </ol>
          <Typography variant="h3" color="primary">
            Your Documents
          </Typography>
          <div className={classes.linkContainer}>
            {documents.map((document) => (
              <div className={classes.linkWrapper} key={document.id}>
                <Link
                  variant="h5"
                  className={classes.link}
                  underline="always"
                  href={document.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <OpenInNewIcon color="secondary" />
                  {document.name}
                </Link>
              </div>
            ))}
          </div>
          <FormControlLabel
            value={isConfirmed}
            classes={{
              root: classnames(
                classes.tile,
                isConfirmed && classes.tileChecked
              ),
            }}
            onChange={handleToggleConfirmation}
            label={
              <Typography
                className={classnames(
                  classes.optionLabel,
                  isConfirmed && classes.optionLabelChecked
                )}
                component="span"
              >
                I confirm that the above information is correct.
              </Typography>
            }
            control={
              <Checkbox
                classes={{ root: classes.radio }}
                color={isConfirmed ? 'secondary' : 'default'}
              />
            }
          />
        </main>
      </SectionCentered>
    </>
  );
};
HomeInsuranceQuote.propTypes = {
  quote: PropTypes.any,
};

export default HomeInsuranceQuote;
