import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import SectionCentered from 'modules/layout/SectionCentered';
import { useNavigation } from '../context/navigation';
import useStyles from '../styles';
import UnspecifiedPersonalPossessions from '../components/ValuableItems/UnspecifiedPersonalPossessions';
import Header from '../components/Header';
import ChoiceUpdate from '../components/Choice';
import SpecifiedItems from '../components/ValuableItems/SpecifiedItems';
import Bicycles from '../components/ValuableItems/Bicycles';

const ValuableItems = ({
  quote,
  updateQuoteField,
  addSpecifiedItem,
  updateSpecifiedItem,
  removeSpecifiedItem,
  addBicycleItem,
  updateBicycleItem,
  removeBicycleItem,
  updateHomeQuote,
}) => {
  const classes = useStyles();
  const { backToQuote } = useNavigation();

  const { requirements } = quote || {};
  const {
    requirementType,
    requirement: { specifiedItemsList, unspecifiedItemsAmount, bicyclesList },
  } = requirements[1];
  const [unspecifiedCoverAmount, setUnspecifiedCoverAmount] = useState(
    unspecifiedItemsAmount || 0
  );

  const handleUpdateUnspecifiedItems = useCallback(() => {
    updateQuoteField(
      requirementType,
      ['unspecifiedItemsAmount'],
      unspecifiedCoverAmount
    );
  }, [requirementType, unspecifiedCoverAmount]);

  return (
    <>
      <SectionCentered
        backgroundColor="grey"
        newLayout
        sectionCenteredClasses={{ root: classes.newMainContent }}
      >
        <Header onBack={backToQuote} />
        <main className={classes.mainContent} style={{ maxWidth: '800px' }}>
          <SpecifiedItems
            specifiedItemsList={specifiedItemsList}
            addSpecifiedItem={addSpecifiedItem}
            updateSpecifiedItem={updateSpecifiedItem}
            removeSpecifiedItem={removeSpecifiedItem}
            requirementType={requirementType}
          />
          <UnspecifiedPersonalPossessions
            value={unspecifiedCoverAmount}
            onChange={setUnspecifiedCoverAmount}
            onSave={handleUpdateUnspecifiedItems}
          />
          <Bicycles
            bicyclesList={bicyclesList}
            addBicycleItem={addBicycleItem}
            updateBicycleItem={updateBicycleItem}
            removeBicycleItem={removeBicycleItem}
            requirementType={requirementType}
          />
        </main>
      </SectionCentered>
      <ChoiceUpdate onQuoteUpdte={updateHomeQuote} />
    </>
  );
};

ValuableItems.propTypes = {
  quote: PropTypes.any,
  updateHomeQuote: PropTypes.func.isRequired,
  updateQuoteField: PropTypes.func.isRequired,
  addSpecifiedItem: PropTypes.func.isRequired,
  updateSpecifiedItem: PropTypes.func.isRequired,
  removeSpecifiedItem: PropTypes.func.isRequired,
  addBicycleItem: PropTypes.func,
  updateBicycleItem: PropTypes.func,
  removeBicycleItem: PropTypes.func,
};

export default ValuableItems;
