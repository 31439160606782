import React from 'react';
import PropTypes from 'prop-types';

import { EditIcon } from '@utilitywarehouse/partner-ui-icons';
import { Button } from '@material-ui/core';

import useStyles from '../styles';

const EditButton = ({ text, onClick }) => {
  const classes = useStyles();
  return (
    <Button
      variant={'text'}
      className={classes.editSimButton}
      onClick={onClick}
      size={'large'}
    >
      <EditIcon />
      <span className={classes.editSimButtonText}>{text}</span>
    </Button>
  );
};

EditButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EditButton;
