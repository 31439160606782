import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TitleBar from 'modules/Shared/TitleBar';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';
import { REQUEST_SENDING } from 'constants/requestStatus';

import styles from './styles';

const Energy = ({
  isFlat = null,
  status = null,
  metersAccessible = null,
  steps,
  selectStep,
  removeStep,
  currentAddressSelected,
  isValidEntryForm,
  update,
  putEntryFormRequest,
  currentAddressLandlineNumber,
  currentAddressLandlineIsVirgin,
  currentAddressOwnThisProperty,
  currentAddressMovedInWithin3Months,
  requireSmartMeterInstalled,
  previousAddressOwnThisProperty,
  previousAddressSelected,
  displayBtnDisabled,
  classes,
}) => (
  <Fragment>
    {steps !== 3 && (
      <TitleBar variant="h4" complete={isValidEntryForm} onClick={selectStep}>
        Energy
      </TitleBar>
    )}
    {currentAddressSelected && steps === 3 && (
      <Paper
        variant="outlined"
        classes={{
          root: classes.container,
        }}
      >
        <Typography variant="h4" component="h4" className={classes.title}>
          Energy
        </Typography>
        {isFlat && (
          <Fragment>
            <Typography
              variant="body1"
              component="p"
              className={classes.question}
            >
              Are both your gas and electricity meters located:
            </Typography>
            <RadioGroup
              aria-label="Meters Accessible"
              name="Meters Accessible"
              value={metersAccessible}
              onChange={(event) => {
                let value = event.target.value === 'true';
                update(value, 'metersAccessible');
                update(value, 'requireSmartMeterInstalled');
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Inside, or within 10 metres, of your flat and you are able to get access to them?"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Greater than 10 metres from your flat, or you are unable to access them?"
              />
            </RadioGroup>
          </Fragment>
        )}
        <div className={classes.accordionNavigation}>
          <RestrictedButton
            size="large"
            primary={false}
            label="Previous"
            onClick={() => removeStep()}
            dataTestId="LandingPageEnergyPrevious"
          />
          <RestrictedButton
            size="large"
            primary
            label="Go to bundles"
            onClick={() => {
              putEntryFormRequest(
                currentAddressLandlineNumber,
                currentAddressLandlineIsVirgin,
                currentAddressOwnThisProperty,
                currentAddressMovedInWithin3Months,
                requireSmartMeterInstalled,
                previousAddressOwnThisProperty,
                previousAddressSelected
              );
            }}
            dataTestId="LandingPageEnergyFinish"
            disabled={
              displayBtnDisabled(isValidEntryForm) || status === REQUEST_SENDING
            }
          />
        </div>
      </Paper>
    )}
  </Fragment>
);

Energy.propTypes = {
  steps: PropTypes.number.isRequired,
  selectStep: PropTypes.func.isRequired,
  status: PropTypes.string,
  isFlat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  metersAccessible: PropTypes.bool,
  removeStep: PropTypes.func,
  currentAddressSelected: PropTypes.shape({
    flatNumber: PropTypes.string,
    street: PropTypes.string,
    subStreet: PropTypes.string,
    town: PropTypes.string,
    postcode: PropTypes.string,
    organisation: PropTypes.string,
    county: PropTypes.string,
    buildingName: PropTypes.string,
    buildingNumber: PropTypes.string,
    city: PropTypes.string,
  }).isRequired,
  isValidEntryForm: PropTypes.bool,
  update: PropTypes.func,
  putEntryFormRequest: PropTypes.func,
  currentAddressLandlineNumber: PropTypes.any,
  currentAddressLandlineIsVirgin: PropTypes.any,
  currentAddressOwnThisProperty: PropTypes.any,
  currentAddressMovedInWithin3Months: PropTypes.any,
  requireSmartMeterInstalled: PropTypes.any,
  previousAddressOwnThisProperty: PropTypes.any,
  previousAddressSelected: PropTypes.any,
  displayBtnDisabled: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Energy);
