export const coverOptions = [
  {
    value: 'buildings',
    label: 'Buildings',
    testId: 'buildings',
  },
  {
    value: 'contents',
    label: 'Contents',
    testId: 'contents',
  },
];
export const excessOptions = [
  {
    value: '50',
    label: '£50',
  },
  {
    value: '100',
    label: '£100',
  },
  {
    value: '200',
    label: '£200',
  },
  {
    value: '300',
    label: '£300',
  },
  {
    value: '400',
    label: '£400',
  },
  {
    value: '500',
    label: '£500',
  },
];

export const homeDisclosures = [
  'Is your main residence and all policyholders are UK residents.',
  'Is self-contained with its own front door, in a good state of repair and not undergoing building work.',
  'Has not been affected by subsidence, landslip, ground heae or been underpinned in the last 25 years',
  'Is not sublet, used for business use (other than clerical), or left unoccupied for more than 60 days',
];

export const personalDisclosures = [
  'No unspent criminal convictions or pending prosections (not including motoring offences).',
  'Not been declared bankrupt in the last 5 years or been the subject of bankruptcy or liquidity proceedings which are not discharged.',
  'Not been the subject of any unsatisfied CCJ proceedings in the past 5 years.',
  'Not had special terms and/or an increased premium imposed on previous insurance policies, or ever had insurance cancelled, refused, or voided.',
];

export const documents = [
  {
    url: '#',
    name: 'Policy wording',
  },
  {
    url: '#',
    name: 'Insurance Product Information',
  },
  {
    url: '#',
    name: 'Terms of Business',
  },
  {
    url: '#',
    name: 'Statements of Fact',
  },
  {
    url: '#',
    name: 'Schedule',
  },
];

export const mockQuote = {
  domain: 'insurance',
  quoteId: '3401cd75-96bd-4f08-ac08-7f19cf10266a',
  provisioningToken: {
    schema: 'insurance/v1',
    token: '3401cd75-96bd-4f08-ac08-7f19cf10266a',
  },
  product: {
    productId: '07ad3822-6785-48fa-8bcc-c5f61ab2a297',
    productClass: 'Home Insurance',
    name: 'Home Insurance - Buildings and Contents',
    description: 'Home Insurance - Buildings and Contents',
    details: {
      documents: [
        {
          id: '',
          name: 'IPID',
          url:
            'https://uw-prod-insurance-assets.s3.eu-west-1.amazonaws.com/documents/home/ipid.v1.pdf',
          creationDate: {
            seconds: 1713894395,
            nanos: 216767841,
          },
        },
        {
          id: '',
          name: 'Terms of Business',
          url:
            'https://uw-prod-insurance-assets.s3.eu-west-1.amazonaws.com/documents/general/terms_of_business.v2.pdf',
          creationDate: {
            seconds: 1713894395,
            nanos: 216767931,
          },
        },
        {
          id: '',
          name: 'Policy Wording',
          url:
            'https://uw-prod-insurance-assets.s3.eu-west-1.amazonaws.com/documents/home/policy_wording.v2.pdf',
          creationDate: {
            seconds: 1713894395,
            nanos: 216768001,
          },
        },
        {
          id: '',
          name: 'Policy Schedule',
          url: '',
          creationDate: {
            seconds: 1713894395,
            nanos: 216768071,
          },
        },
        {
          id: '',
          name: 'Statement of Fact',
          url: '',
          creationDate: {
            seconds: 1713894395,
            nanos: 216768141,
          },
        },
      ],
      standardExcess: '400.00',
      subsidenceExcess: '1000.00',
      escapeOfWaterExcess: '500.00',
    },
    subProducts: [],
  },
  requirements: [
    {
      quoteId: '3401cd75-96bd-4f08-ac08-7f19cf10266a',
      requirementType:
        'type.googleapis.com/insurance.ordering_platform.home.v1.ReqAdditionalCover',
      requirement: {
        buildingsAccidentalDamage: false,
        contentsAccidentalDamage: false,
      },
    },
    {
      quoteId: '3401cd75-96bd-4f08-ac08-7f19cf10266a',
      requirementType:
        'type.googleapis.com/insurance.ordering_platform.home.v1.ReqAdditionalItems',
      requirement: {
        unspecifiedItemsAmount: '0.0',
        specifiedItemsList: [],
        bicyclesList: [],
      },
    },
    {
      quoteId: '3401cd75-96bd-4f08-ac08-7f19cf10266a',
      requirementType:
        'type.googleapis.com/insurance.ordering_platform.home.v1.ReqPreviousClaims',
      requirement: {
        claimsList: [],
      },
    },
    {
      quoteId: '3401cd75-96bd-4f08-ac08-7f19cf10266a',
      requirementType:
        'type.googleapis.com/insurance.ordering_platform.home.v1.ReqStartDate',
      requirement: {
        date: {
          seconds: 1714089600,
          nanos: 0,
        },
      },
    },
    {
      quoteId: '3401cd75-96bd-4f08-ac08-7f19cf10266a',
      requirementType:
        'type.googleapis.com/insurance.ordering_platform.home.v1.ReqHomeOwner',
      requirement: {
        homeOwner: true,
      },
    },
  ],
  charges: [
    {
      amount: {
        currency: 1,
        value: '23.31',
      },
      name: 'Monthly Premium',
      description: 'The amount added to your monthly bill',
      monthlyRecurring: {
        instalments: 0,
      },
    },
  ],
};
