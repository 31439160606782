import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const Step = ({ alternate, classes, description, Image, label, title }) => (
  <div className={classNames(classes.step, alternate && classes.alternate)}>
    <div className={classes.stepInfo}>
      <Typography color="primary" paragraph>
        {label}
      </Typography>
      <Typography color="primary" component="h3" paragraph variant="h3">
        {title}
      </Typography>
      <Typography paragraph>{description}</Typography>
    </div>
    <div className={classes.stepImage}>
      <Image />
    </div>
  </div>
);

Step.propTypes = {
  alternate: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  Image: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

Step.defaultProps = {
  alternate: false,
};

export default withStyles(styles)(Step);
