import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash/fp';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import AddressPicker from 'redux/modules/AddressPicker/container';
import { ADDRESS_GET_STARTED_PATH } from 'redux/modules/EntryForm/sectionPaths';
import PageHeading from 'components/modules/Shared/PageHeading';
import SectionCentered from 'modules/layout/SectionCentered';
import LoadingIndicator from 'modules/Shared/LoadingIndicator';
import Questionnaire from './Questionnaire';
import styles from './styles';
import SessionDialog from './SessionDialog';

const LEAD_NOTICE = (lead) =>
  `You are proceeding with the referral from the customer ${lead.customerAccountNumber}`;
const LEAD_ERROR = (lead) =>
  `There was a problem retrieving the sales lead, or the lead could not be found, for the code ${lead.code}.`;

export class EntryFormUI extends Component {
  render() {
    const {
      fieldValues,
      lead,
      leadError,
      update,
      isLoading,
      isRemoteSession,
      updateAnalyticsPage,
      existingApplication,
      existingSessionAnswer,
      classes,
    } = this.props;
    const { currentAddressSelected } = fieldValues;
    const shouldShowLeadNotification =
      !isLoading && !!get('customerAccountNumber')(lead);
    const shouldShowLeadError = !isLoading && leadError && !!get('code')(lead);

    return (
      <div className={classes.base}>
        {existingApplication && (
          <SessionDialog
            action={existingSessionAnswer}
            isRemoteSession={isRemoteSession}
          />
        )}
        <PageHeading autoScroll={false} title="Start saving today" />
        <SectionCentered>
          <div className={classes.section}>
            <div className={classes.contentContainer}>
              {isLoading && <LoadingIndicator loading />}
              {shouldShowLeadNotification && (
                <Alert
                  className={classes.salesLeadNotice}
                  severity="info"
                  data-test-id="lead-notification"
                >
                  {LEAD_NOTICE(lead)}
                </Alert>
              )}
              {shouldShowLeadError && (
                <Alert
                  className={classes.salesLeadNotice}
                  severity="error"
                  data-test-id="lead-error"
                >
                  {LEAD_ERROR(lead)}
                </Alert>
              )}
              <Typography className={classes.title} variant="h2">
                Just a few more details
              </Typography>
              <div className={classes.row}>
                <div className={classes.left}>
                  {!currentAddressSelected && (
                    <AddressPicker
                      update={(address, field) => update(address, field)}
                      updateAnalyticsPage={() => {
                        const { name, path } = ADDRESS_GET_STARTED_PATH;
                        updateAnalyticsPage(name, { path });
                      }}
                    />
                  )}
                  {currentAddressSelected && <Questionnaire {...this.props} />}
                </div>
              </div>
            </div>
          </div>
        </SectionCentered>
      </div>
    );
  }
}

EntryFormUI.propTypes = {
  lead: PropTypes.shape({
    code: PropTypes.string,
    customerAccountNumber: PropTypes.string,
    id: PropTypes.string,
    referredBy: PropTypes.string,
  }),
  leadError: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  fieldValues: PropTypes.object,
  existingApplication: PropTypes.bool,
  isRemoteSession: PropTypes.bool.isRequired,
  existingSessionAnswer: PropTypes.func.isRequired,
  updateAnalyticsPage: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EntryFormUI);
