export default (theme) => ({
  container: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  spacing: {
    paddingBottom: theme.spacing(2),
  },

  containerSpacing: {
    marginBottom: theme.spacing(2),
  },
  question: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  addressPicker: {
    marginTop: theme.spacing(2),
  },
  editAddressBtn: {
    marginTop: theme.spacing(1),
  },
  textField: {
    flex: 1,
    maxWidth: 370,
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
    },
  },
  noLandline: {
    marginRight: 0,
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(4),
    },
  },
  accordionNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: 100,
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      flexDirection: 'row',
    },
  },
  landlineFields: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'stretch',
      flex: 1,
    },
  },
});
