import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import formatPostcode from 'redux/utils/formatPostcode';

const ViewAddress = ({ address, className }) => {
  const {
    flatNumber,
    street,
    subStreet,
    town,
    postcode,
    organisation,
    county,
    buildingName,
    buildingNumber,
    city,
  } = address;

  const addressLines = [];

  if (organisation) {
    addressLines.push(organisation);
  }
  if (flatNumber) {
    addressLines.push(flatNumber);
  }
  if (buildingName) {
    addressLines.push(buildingName);
  }
  if (buildingNumber) {
    addressLines.push(buildingNumber);
  }
  if (subStreet) {
    addressLines.push(subStreet);
  }
  if (street) {
    addressLines.push(street);
  }
  if (town) {
    addressLines.push(town);
  }
  if (city) {
    addressLines.push(city);
  }
  if (county) {
    addressLines.push(county);
  }
  if (postcode) {
    addressLines.push(formatPostcode(postcode));
  }

  return (
    <Typography
      variant="h4"
      component="h2"
      className={className}
      data-test-id="EntryFormAddress"
    >
      {addressLines.join(', ')}
    </Typography>
  );
};

ViewAddress.propTypes = {
  address: PropTypes.shape({
    flatNumber: PropTypes.string,
    street: PropTypes.string,
    subStreet: PropTypes.string,
    town: PropTypes.string,
    postcode: PropTypes.string,
    organisation: PropTypes.string,
    county: PropTypes.string,
    buildingName: PropTypes.string,
    buildingNumber: PropTypes.string,
    city: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};

export default ViewAddress;
