import React from 'react';
import PropTypes from 'prop-types';

import { InputAdornment, Button } from '@material-ui/core';
import { CloseIcon } from '@utilitywarehouse/partner-ui-icons';
import TextField from 'modules/CustomerUI/TextField';

import Select from '../../Select';
import { BicycleTypeArray } from '../../../utils/const';

const ItemEditForm = ({ itemState, onItemChange, onSave, onRemove }) => {
  return (
    <>
      <Select
        options={BicycleTypeArray}
        value={itemState?.type}
        label="What is the type of the item?"
        onChange={(value) => onItemChange('type', value)}
      />
      <TextField
        value={itemState?.value}
        type="number"
        fullWidth
        placeholder="Enter amount"
        onChange={(_, value) => onItemChange('value', value)}
        label="How much would it cost to replace your item?"
        InputProps={{
          startAdornment: <InputAdornment position="start">£</InputAdornment>,
        }}
      />
      <TextField
        value={itemState?.description}
        type="text"
        fullWidth
        placeholder="Item description"
        onChange={(_, value) => onItemChange('description', value)}
        label="Please give more details about your item"
        helperText="Do include make, model and serial number."
      />
      <Button onClick={onSave} variant="contained" size="medium">
        Save Details
      </Button>
      <Button
        onClick={onRemove}
        variant="text"
        size="medium"
        startIcon={<CloseIcon fontSize="small" color="error" />}
      >
        Remove
      </Button>
    </>
  );
};

ItemEditForm.propTypes = {
  itemState: PropTypes.shape({
    type: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    awayFromHome: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
  }),
  onItemChange: PropTypes.func,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
};

export default ItemEditForm;
