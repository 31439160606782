export default {
  accordionYesNo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: 250,
  },
  option: {
    width: 115,
  },
};
