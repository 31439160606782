import { getBreakpoints, getSpacing } from 'app/lib/theme';

export default (theme) => {
  const breakpoints = getBreakpoints(theme);
  const spacing = getSpacing(theme);

  return {
    alternate: {
      [breakpoints.up('sm')]: {
        '& $stepInfo': {
          margin: spacing(0, 0, 0, 10),
          order: 1,
        },
      },
    },
    base: {
      margin: spacing(5, 0, 0),
      [breakpoints.up('md')]: {
        margin: spacing(10, 0, 0),
      },
    },
    content: {
      [breakpoints.up('md')]: {
        paddingRight: '50%',
      },
    },
    step: {
      margin: spacing(2.5, 0),
      [breakpoints.up('sm')]: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        margin: spacing(5, 0),
      },
    },
    stepInfo: {
      marginRight: spacing(10),
      [breakpoints.up('sm')]: {
        flexGrow: 1,
        maxWidth: '50%',
      },
    },
    stepImage: {
      [breakpoints.down('xs')]: {
        margin: '0 auto',
        maxWidth: 320,
      },
      [breakpoints.up('sm')]: {
        flexBasis: '50%',
        flexShrink: 0,
      },
      [breakpoints.up('md')]: {
        flexBasis: 460,
      },
    },
    stepOneImage: {
      maxWidth: '80%',
    },
    stepTwoImage: {
      maxWidth: '70%',
      transform: 'scaleX(-1)',
    },
    stepThreeImage: {
      maxWidth: '70%',
    },
  };
};
