import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { APP_START_AGAIN } from 'redux/modules/App/constants';
import SectionCentered from 'modules/layout/SectionCentered';
import useStyles from './styles';

const LANDING_PAGE = '/';

const Missing = ({ redirectUrl = LANDING_PAGE }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const handleRedirect = () => {
    // fire the startAgain action if redirecting to the landing page
    if (redirectUrl === LANDING_PAGE) dispatch({ type: APP_START_AGAIN });
    else history.push(redirectUrl);
  };

  useEffect(() => {
    const timer = setTimeout(handleRedirect, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <SectionCentered sectionCenteredClasses={{ container: classes.container }}>
      <Typography color="primary" variant="h2">
        We could not find your application, please start again
      </Typography>
      <Typography variant="subtitle2">
        Click the button below to go back to the home page if you are not
        redirected automatically.
      </Typography>
      <Button variant="contained" onClick={handleRedirect}>
        Back to Home Page
      </Button>
    </SectionCentered>
  );
};

Missing.propTypes = {
  redirectUrl: PropTypes.string,
};

export default Missing;
