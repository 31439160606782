import { getBreakpoints, getSpacing } from 'app/lib/theme';

export default (theme) => {
  const breakpoints = getBreakpoints(theme);
  const spacing = getSpacing(theme);

  return {
    display: {
      backgroundImage: 'url(images/ben_fogle.jpg)',
      backgroundRepeat: 'no-repeat',
      position: 'relative',
      '&:before': {
        bottom: 0,
        content: '""',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
      },
      [breakpoints.down('sm')]: {
        backgroundSize: 'cover',
        paddingTop: 150,
        backgroundPosition: 'top center',
      },
      [breakpoints.up('md')]: {
        backgroundPosition: 'top right',
        backgroundSize: 'cover',
        height: 0,
        overflow: 'hidden',
        paddingTop: '50%',
      },
    },
    content: {
      padding: spacing(4, 0),
      position: 'relative',
      zIndex: 1,
      [breakpoints.up('md')]: {
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        right: 0,
        maxWidth: 550,
        position: 'absolute',
        top: 0,
      },
    },
    quote: {
      color: theme.palette.common.white,
      marginRight: theme.spacing(10),
      textAlign: 'right',
      [breakpoints.only('md')]: {
        fontSize: 42,
      },
      '&:before': {
        backgroundImage: 'url(images/quotes-opening.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'right',
        content: '""',
        display: 'block',
        height: 50,
        marginBottom: theme.spacing(1),
        position: 'relative',
      },
    },
    fogleQuote: {
      color: theme.palette.common.white,
      margin: theme.spacing(10, 10, 0, 0),
      textAlign: 'right',
      [breakpoints.only('md')]: {
        margin: theme.spacing(2, 10, 0, 0),
      },
    },
    fogleRole: {
      color: theme.palette.common.white,
      fontSize: 20,
      marginRight: theme.spacing(10),
      textAlign: 'right',
    },
  };
};
