import { makeStyles } from '@material-ui/core/styles';
import { getBreakpoints, getSpacing } from 'app/lib/theme';

export const useStyles = makeStyles((theme) => {
  const breakpoints = getBreakpoints(theme);
  const spacing = getSpacing(theme);

  return {
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: spacing(2),
      width: '100%',
      [breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    button: {
      marginTop: spacing(1),
      [breakpoints.up('sm')]: {
        marginTop: 'auto',
      },
    },
  };
});
