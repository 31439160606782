import React from 'react';
import PropTypes from 'prop-types';

import { InputAdornment, Button, Typography } from '@material-ui/core';
import { CloseIcon } from '@utilitywarehouse/partner-ui-icons';
import TextField from 'modules/CustomerUI/TextField';
import DatePicker from 'components/modules/Shared/DatePicker';
import RadioButtonGroup from '../../../Shared/MultiSelection/RadioButtonGroup';
import useStyles from '../../styles';
import Select from '../Select';
import { ClaimTypesArray, boolOptions } from '../../utils/const';

const ItemEditForm = ({ itemState, onItemChange, onSave, onRemove }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h4">Claim Details</Typography>
      <DatePicker
        label="When did you make this claim?"
        onChange={(e, value) => onItemChange('date', value)}
        value={
          itemState.date.seconds
            ? new Date(itemState.date.seconds * 1000)
            : itemState.date
        }
        fullWidth
        textFieldClasses={{ form: classes.textField }}
      />
      <Select
        options={ClaimTypesArray}
        value={itemState?.type}
        label="What type of claim was made?"
        onChange={(value) => onItemChange('type', value)}
      />
      <RadioButtonGroup
        options={boolOptions}
        value={itemState?.livingInProperty}
        onChange={(event) =>
          onItemChange('livingInProperty', JSON.parse(event.target.value))
        }
        label="Were you living at the property to be insured when you made this claim?"
      />
      <TextField
        value={itemState?.amount}
        textFieldClasses={{ form: classes.textField }}
        type="number"
        fullWidth
        placeholder="Enter amount"
        onChange={(_, value) => onItemChange('amount', value)}
        label="What was the amount claimed?"
        InputProps={{
          startAdornment: <InputAdornment position="start">£</InputAdornment>,
        }}
      />
      <RadioButtonGroup
        options={[
          { value: false, label: 'Ongoing' },
          { value: true, label: 'Settled' },
        ]}
        value={itemState?.settled}
        onChange={(event) =>
          onItemChange('settled', JSON.parse(event.target.value))
        }
        label="What is the status of this claim?"
      />
      <Button onClick={onSave} variant="contained" size="medium">
        Save Details
      </Button>
      <Button
        onClick={onRemove}
        variant="text"
        size="medium"
        startIcon={<CloseIcon fontSize="small" color="error" />}
      >
        Remove claim
      </Button>
    </>
  );
};

ItemEditForm.propTypes = {
  itemState: PropTypes.shape({
    type: PropTypes.string.isRequired,
    cover: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    date: PropTypes.date,
    livingInProperty: PropTypes.bool,
    settled: PropTypes.bool,
  }),
  onItemChange: PropTypes.func,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
};

export default ItemEditForm;
