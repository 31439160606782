import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TitleBar from 'modules/Shared/TitleBar';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';
import AddressLines from 'components/modules/Shared/AddressLines';
import AddressPicker from 'redux/modules/AddressPicker/container';
import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { FLAGS } from 'redux/modules/FeatureFlags/constants';
import styles from './styles';
import YesNoButtonCombo from '../../YesNoButtonCombo';

export const Property = ({
  addStep,
  selectStep,
  steps,
  maxStep,
  removeStep,
  currentAddressSelected,
  currentAddressOwnThisProperty,
  currentAddressMovedInWithin3Months,
  isFlat,
  currentAddressLandlineNumber,
  currentAddressLandlineIsVirgin,
  putEntryFormRequest,
  previousAddressOwnThisProperty,
  previousAddressSelected,
  update,
  requireSmartMeterInstalled,
  classes,
}) => {
  const propertyQuestionsEnabled = !useSelector(
    FeatureFlagsApi.getFlagSelector(FLAGS.DETAILS_PROPERTY_QUESTIONS)
  );

  const showTimeQuestion = () => currentAddressOwnThisProperty === 'yes';
  const showPreviousOwnershipQuestion = () =>
    currentAddressMovedInWithin3Months === true;

  const showPreviousAddressPicker = () =>
    previousAddressOwnThisProperty === true &&
    currentAddressMovedInWithin3Months === true;

  const formComplete = () => {
    const ownership =
      currentAddressOwnThisProperty === 'yes' ||
      currentAddressOwnThisProperty === 'no';
    const tenant = currentAddressOwnThisProperty === 'no' && isFlat !== '';

    const longtermOwner =
      currentAddressOwnThisProperty === 'yes' &&
      currentAddressMovedInWithin3Months === false;

    const firsttimeOwner =
      currentAddressOwnThisProperty === 'yes' &&
      currentAddressMovedInWithin3Months === true &&
      previousAddressOwnThisProperty === false;

    const previousOwner =
      currentAddressOwnThisProperty === 'yes' &&
      currentAddressMovedInWithin3Months === true &&
      previousAddressOwnThisProperty === true &&
      previousAddressSelected;

    if (propertyQuestionsEnabled) {
      return tenant || longtermOwner || firsttimeOwner || previousOwner;
    }

    return ownership;
  };

  return (
    <Fragment>
      {steps !== 2 && (
        <TitleBar variant="h4" complete={maxStep > 2} onClick={selectStep}>
          Property
        </TitleBar>
      )}

      {currentAddressSelected && steps === 2 && (
        <Paper
          variant="outlined"
          classes={{
            root: classes.container,
          }}
        >
          <Typography variant="h4" component="h4" className={classes.title}>
            Property
          </Typography>
          {currentAddressSelected && (
            <Fragment>
              <Typography
                variant="body1"
                component="p"
                className={classes.question}
              >
                Do you own this property?
              </Typography>
              <YesNoButtonCombo
                expectedYesValue="yes"
                expectedNoValue="no"
                onYes={() => {
                  update('yes', 'currentAddressOwnThisProperty');
                  update('', 'isFlat');
                }}
                onNo={() => {
                  update('no', 'currentAddressOwnThisProperty');
                  update('', 'currentAddressMovedInWithin3Months');
                }}
                valueSelected={currentAddressOwnThisProperty}
                yesDataTestId="LandingPageOwnPropertyYes"
                noDataTestId="LandingPageOwnPropertyNo"
              />
            </Fragment>
          )}

          {propertyQuestionsEnabled && (
            <>
              {currentAddressSelected && showTimeQuestion() && (
                <Fragment>
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.question}
                  >
                    Have you moved into your property in the last 3 months?
                  </Typography>
                  <YesNoButtonCombo
                    expectedYesValue
                    expectedNoValue={false}
                    onYes={() =>
                      update(true, 'currentAddressMovedInWithin3Months')
                    }
                    onNo={() => {
                      update(false, 'currentAddressMovedInWithin3Months');
                      update('', 'previousAddressOwnThisProperty');
                    }}
                    valueSelected={currentAddressMovedInWithin3Months}
                    yesDataTestId="LandingPage3MonthsYes"
                    noDataTestId="LandingPage3MonthsNo"
                  />
                </Fragment>
              )}

              {currentAddressSelected && showPreviousOwnershipQuestion() && (
                <Fragment>
                  <Typography
                    variant="body1"
                    component="p"
                    className={classes.question}
                  >
                    Did you own your previous home?
                  </Typography>
                  <YesNoButtonCombo
                    expectedYesValue
                    expectedNoValue={false}
                    onYes={() => update(true, 'previousAddressOwnThisProperty')}
                    onNo={() => {
                      update(false, 'previousAddressOwnThisProperty');
                      update('', 'previousAddressSelected');
                    }}
                    valueSelected={previousAddressOwnThisProperty}
                    yesDataTestId="LandingPagePreviousAddressOwnedYes"
                    noDataTestId="LandingPagePreviousAddressOwnedNo"
                  />
                </Fragment>
              )}

              {currentAddressSelected &&
                !showTimeQuestion() &&
                currentAddressOwnThisProperty !== undefined && (
                  <Fragment>
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.question}
                    >
                      Is your property a flat?
                    </Typography>
                    <YesNoButtonCombo
                      expectedYesValue
                      expectedNoValue={false}
                      onYes={() => update(true, 'isFlat')}
                      onNo={() => {
                        update(false, 'isFlat');
                        update(true, 'requireSmartMeterInstalled');
                      }}
                      valueSelected={isFlat}
                      yesDataTestId="LandingPageIsFlatYes"
                      noDataTestId="LandingPageIsFlatNo"
                    />
                  </Fragment>
                )}

              {currentAddressSelected &&
                showPreviousAddressPicker() &&
                !previousAddressSelected && (
                  <Fragment>
                    <Typography
                      variant="body1"
                      component="p"
                      className={classes.question}
                    >
                      Your previous address
                    </Typography>

                    <AddressPicker
                      update={(address) =>
                        update(address, 'previousAddressSelected')
                      }
                    />
                  </Fragment>
                )}

              {currentAddressSelected &&
                showPreviousAddressPicker() &&
                previousAddressSelected && (
                  <div className={classes.addressPicker}>
                    <AddressLines
                      address={previousAddressSelected}
                      title={
                        <Typography
                          variant="body1"
                          component="p"
                          className={classes.question}
                        >
                          Your previous address
                        </Typography>
                      }
                      actions={
                        <RestrictedButton
                          label="Edit"
                          variant="outlined"
                          onClick={() =>
                            update(null, 'previousAddressSelected')
                          }
                          classNames={{ root: classes.editAddressBtn }}
                        />
                      }
                    />
                  </div>
                )}
            </>
          )}

          <div className={classes.accordionNavigation}>
            <RestrictedButton
              size="large"
              variant="outlined"
              label="Previous"
              onClick={() => removeStep()}
              dataTestId="LandingPagePropertyPrevious"
            />
            {(currentAddressOwnThisProperty === 'no' ||
              currentAddressOwnThisProperty === undefined) &&
              isFlat && (
                <RestrictedButton
                  size="large"
                  variant="contained"
                  tabIndex={-1}
                  label="Next"
                  onClick={() => addStep()}
                  disabled={!formComplete()}
                  dataTestId="LandingPagePropertyNext"
                />
              )}
            {(currentAddressOwnThisProperty === 'yes' || !isFlat) && (
              <RestrictedButton
                size="large"
                variant="contained"
                tabIndex={0}
                label="Go to bundles"
                disabled={!formComplete()}
                onClick={() => {
                  putEntryFormRequest(
                    currentAddressLandlineNumber,
                    currentAddressLandlineIsVirgin,
                    currentAddressOwnThisProperty,
                    currentAddressMovedInWithin3Months,
                    requireSmartMeterInstalled,
                    previousAddressOwnThisProperty,
                    previousAddressSelected
                  );
                }}
                dataTestId="LandingPageEntryFormFinishTenant"
              />
            )}
          </div>
        </Paper>
      )}
    </Fragment>
  );
};

Property.default = {
  isFlat: null,
};

Property.propTypes = {
  steps: PropTypes.number.isRequired,
  maxStep: PropTypes.number.isRequired,
  selectStep: PropTypes.func.isRequired,
  isFlat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  classes: PropTypes.object.isRequired,
  addStep: PropTypes.func.isRequired,
  removeStep: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  currentAddressSelected: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.string),
      PropTypes.bool,
    ])
  ).isRequired,
  currentAddressOwnThisProperty: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  currentAddressMovedInWithin3Months: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  currentAddressLandlineNumber: PropTypes.string,
  currentAddressLandlineIsVirgin: PropTypes.string,
  putEntryFormRequest: PropTypes.func.isRequired,
  previousAddressOwnThisProperty: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  previousAddressSelected: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.string),
      PropTypes.bool,
    ])
  ),
  requireSmartMeterInstalled: PropTypes.bool,
};

export default withStyles(styles)(Property);
