import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Typography, Button } from '@material-ui/core';
import { ChevronLeftIcon, AddIcon } from '@utilitywarehouse/partner-ui-icons';

import SectionCentered from 'modules/layout/SectionCentered';
import ClaimsItemsCard from '../components/Claims/Card';
import ChoiceUpdate from '../components/Choice';

import useStyles from '../styles';
import { useNavigation } from '../context/navigation';

const subtitle =
  'If you or anyone living in your property has made a home insurance claim in the last 5 years, please add them below.';

const PrevClaims = ({
  quote,
  addClaim,
  updateClaim,
  removeClaim,
  updateHomeQuote,
}) => {
  const classes = useStyles();
  const { backToQuote } = useNavigation();
  const [isNewItem, setNewItem] = useState(false);
  const { requirements } = quote || {};
  const {
    requirementType,
    requirement: { claimsList },
  } = requirements[2];

  const handleCloseNewItem = useCallback(() => setNewItem(false), []);

  return (
    <>
      <SectionCentered
        backgroundColor="grey"
        newLayout
        sectionCenteredClasses={{ root: classes.newMainContent }}
      >
        <header className={classes.header}>
          <Button
            variant={'text'}
            className={classes.editSimButton}
            onClick={backToQuote}
            size={'large'}
          >
            <ChevronLeftIcon />
            <span className={classes.editSimButtonText}>Back</span>
          </Button>
        </header>
        <main className={classes.mainContent}>
          <Typography variant="h3">Previous Claims</Typography>
          <Typography variant="body1">{subtitle}</Typography>
          {claimsList?.map((item, index) => (
            <ClaimsItemsCard
              key={item.id}
              index={index}
              requirementType={requirementType}
              item={item}
              onUpdate={updateClaim}
              onRemove={removeClaim}
              onClose={handleCloseNewItem}
            />
          ))}
          {isNewItem && (
            <ClaimsItemsCard
              isNewItem
              requirementType={requirementType}
              onUpdate={updateClaim}
              onRemove={removeClaim}
              onAdd={addClaim}
              onClose={handleCloseNewItem}
            />
          )}
          <Button
            variant="outlined"
            className={classes.select}
            size="medium"
            startIcon={<AddIcon fontSize="small" />}
            onClick={() => setNewItem(true)}
            disabled={isNewItem}
          >
            Add claim
          </Button>
        </main>
      </SectionCentered>
      <ChoiceUpdate onQuoteUpdte={updateHomeQuote} />
    </>
  );
};
PrevClaims.propTypes = {
  quote: PropTypes.any,
  updateHomeQuote: PropTypes.func.isRequired,
  addClaim: PropTypes.func,
  updateClaim: PropTypes.func,
  removeClaim: PropTypes.func,
};

export default PrevClaims;
