import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, InputAdornment } from '@material-ui/core';
import TextField from 'modules/CustomerUI/TextField';
import useStyles from '../../styles';

const UnspecifiedPersonalPossessions = ({ value, onChange, onSave }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h3">Unspecified personal possessions</Typography>
      <Typography variant="body1">
        Select an amount to get covered for valuables that may be with you
        outside of the home.
      </Typography>
      <TextField
        textFieldClasses={{ form: classes.input }}
        InputProps={{
          startAdornment: <InputAdornment position="start">£</InputAdornment>,
        }}
        value={value}
        type="number"
        fullWidth
        placeholder="Enter amount"
        onChange={(_, value) => onChange(value)}
        label="How much cover would you like to add?"
        helperText="The limit for a single item is £1500. Add items above this value to the valuable items section above"
      />
      <Button
        variant="outlined"
        className={classes.select}
        size="medium"
        onClick={onSave}
      >
        Test save state
      </Button>
    </>
  );
};

UnspecifiedPersonalPossessions.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default UnspecifiedPersonalPossessions;
