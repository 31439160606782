import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import useEvents from 'app/lib/analytics/AnalyticsProvider/useEvents';
import { EVENTS } from 'app/lib/analytics/constants';
import TitleBar from 'modules/Shared/TitleBar';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';
import ViewAddress from '../../ViewAddress';
import styles from './styles';

const Intro = ({
  selectStep,
  currentAddressSelected,
  steps,
  update,
  updateAnalyticsPage,
  previousPath,
  postAddressRequest,
  addStep,
  addressLoading,
  classes,
}) => {
  const analytics = useTracking();
  const applicationStartBody = useEvents(EVENTS.APPLICATION_STARTED);

  return (
    <Fragment>
      {steps !== 0 && (
        <TitleBar variant="h4" complete onClick={selectStep}>
          Your Address
        </TitleBar>
      )}
      {currentAddressSelected && steps === 0 && (
        <Paper
          variant="outlined"
          classes={{
            root: classes.container,
          }}
        >
          <Typography variant="h4" component="h4" className={classes.title}>
            Your Address
          </Typography>
          <ViewAddress
            address={currentAddressSelected}
            className={classes.spacing}
          />
          <Typography variant="body1" component="p" className={classes.spacing}>
            This is the address that we are going to use to go through the
            signup process, you will not be able to edit it later. If it
            isn&apos;t the correct one, please edit it via the
            <b> &apos;Edit address&apos;</b> button.
          </Typography>
          <Alert
            severity="info"
            variant="filled"
            classes={{ root: classes.containerSpacing }}
          >
            In order to give you the best possible deal we&apos;ll need some
            further information.
          </Alert>
          <div className={classes.accordionNavigation}>
            <Button
              variant="outlined"
              size="large"
              onClick={() => {
                update(undefined, 'currentAddressSelected');
                const { name, path } = previousPath;
                updateAnalyticsPage(name, { path });
              }}
            >
              Edit address
            </Button>
            <RestrictedButton
              size="large"
              variant="contained"
              label="Next"
              loading={addressLoading}
              onClick={() => {
                analytics.track(
                  EVENTS.APPLICATION_STARTED,
                  applicationStartBody
                );
                postAddressRequest(currentAddressSelected, addStep);
              }}
              disabled={addressLoading}
              dataTestId="LandingPageEntryFormViewAddressGetStarted"
            />
          </div>
        </Paper>
      )}
    </Fragment>
  );
};

Intro.propTypes = {
  steps: PropTypes.number.isRequired,
  currentAddressSelected: PropTypes.shape({
    flatNumber: PropTypes.string,
    street: PropTypes.string,
    subStreet: PropTypes.string,
    town: PropTypes.string,
    postcode: PropTypes.string,
    organisation: PropTypes.string,
    county: PropTypes.string,
    buildingName: PropTypes.string,
    buildingNumber: PropTypes.string,
    city: PropTypes.string,
  }).isRequired,
  selectStep: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateAnalyticsPage: PropTypes.func.isRequired,
  postAddressRequest: PropTypes.func.isRequired,
  previousPath: PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
  addStep: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  addressLoading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Intro);
