import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';
import moment from 'moment';
import Button from 'modules/Shared/Button';
import LoadingIndicator from 'modules/Shared/LoadingIndicator';
import DateInput from 'modules/Shared/DateInput';
import styles from './styles';
import { DESCRIPTION, ERROR, THANKS, TITLE } from './copy';

const HomeInsuranceRenewal = ({
  classes,
  fields,
  updateField,
  validate,
  submit,
  formComplete,
  submitted,
  loading,
  error,
}) => {
  const showForm = !submitted && !loading && !error;
  const { date } = fields;
  return (
    <Card className={classes.container} variant="outlined">
      <CardContent className={classes.content}>
        <Typography component="h3" paragraph variant="h3">
          {TITLE}
        </Typography>
        <Typography paragraph>{DESCRIPTION}</Typography>
        {showForm && (
          <Fragment>
            <div className={classes.formRow}>
              <DateInput
                withDays
                dataTestId="HomeInsuranceRenewalDateInput"
                day={date ? moment(date, 'DD-MM-YYYY').get('date') : undefined}
                month={
                  date ? moment(date, 'DD-MM-YYYY').get('month') + 1 : undefined
                }
                year={date ? moment(date, 'DD-MM-YYYY').get('year') : undefined}
                errorText={fields.error || undefined}
                onChange={(value) => {
                  const formatted = value
                    ? moment(value).format('DD-MM-YYYY')
                    : undefined;
                  updateField(formatted);
                }}
                validate={() => validate(date)}
                required
              />
            </div>
            <Button
              classNames={{ root: classes.submit }}
              variant="contained"
              onClick={formComplete ? submit : undefined}
              disabled={!formComplete}
            >
              Submit
            </Button>
          </Fragment>
        )}
        {loading && <LoadingIndicator loading />}
        {submitted && (
          <Typography className={classes.submitted} paragraph>
            {THANKS}
          </Typography>
        )}
        {error && (
          <Typography className={classes.error} paragraph>
            {ERROR}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

HomeInsuranceRenewal.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  formComplete: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  submitted: PropTypes.bool.isRequired,
  updateField: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
};

export default withStyles(styles)(HomeInsuranceRenewal);
