import React from 'react';
import PropTypes from 'prop-types';

import HomeInsurance from './container';
import { NavigationProvider } from './context/navigation';

const HomeInsurancePage = ({ ...props }) => {
  return (
    <NavigationProvider>
      <HomeInsurance {...props} />
    </NavigationProvider>
  );
};
HomeInsurancePage.propTypes = {
  quote: PropTypes.any,
  updateQuoteField: PropTypes.func,
  addSpecifiedItem: PropTypes.func,
  updateSpecifiedItem: PropTypes.func,
  removeSpecifiedItem: PropTypes.func,
  addClaim: PropTypes.func,
  updateClaim: PropTypes.func,
  removeClaim: PropTypes.func,
  getHomeInsuranceQuoteRequest: PropTypes.func,
};
export default HomeInsurancePage;
