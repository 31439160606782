import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

import NavigationPane from 'modules/Shared/NavigationButton/NavigationPane';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';

const ChoiceUpdate = ({ onQuoteUpdte }) => {
  const theme = useTheme();

  return (
    <NavigationPane
      helpCtaEnabled
      topColor={theme.palette.common.white}
      back={false}
    >
      <RestrictedButton
        variant="contained"
        onClick={onQuoteUpdte}
        dataTestId="BoilerCoverAcceptQuote"
        label="Update cover"
      />
    </NavigationPane>
  );
};

ChoiceUpdate.propTypes = {
  onQuoteUpdte: PropTypes.func,
};

export default ChoiceUpdate;
