import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Helmet from 'react-helmet';
import {
  YOUR_ADDRESS_PATH,
  ADDRESS_GET_STARTED_PATH,
  LANDLINE_PATH,
  PROPERTY_PATH,
  ENERGY_PATH,
} from 'redux/modules/EntryForm/sectionPaths';
import AddressExistsDialog from './AddressExistsDialog';
import Intro from './Questions/intro';
import Homephone from './Questions/homephone';
import Property from './Questions/property';
import Energy from './Questions/energy';
import styles from '../styles';

class EntryFormQuestionnaire extends React.Component {
  static getTitleFromSection(step) {
    const sections = [
      ADDRESS_GET_STARTED_PATH,
      LANDLINE_PATH,
      PROPERTY_PATH,
      ENERGY_PATH,
    ].map(({ name }) => name);

    return sections[step];
  }

  setStep(steps, pathName) {
    const { questionnaire, setStep, updateAnalyticsPage } = this.props;
    if (steps <= questionnaire.maxStep) {
      setStep(steps);
      const { name, path } = pathName;
      updateAnalyticsPage(name, { path });
    }
  }

  addStep(pathName) {
    const { addStep, updateAnalyticsPage } = this.props;
    addStep();
    const { name, path } = pathName;
    updateAnalyticsPage(name, { path });
  }

  removeStep(pathName) {
    const { removeStep, updateAnalyticsPage } = this.props;
    removeStep();
    const { name, path } = pathName;
    updateAnalyticsPage(name, { path });
  }

  render() {
    const displayBtnDisabled = (isValidEntryForm) => !isValidEntryForm === true;

    const {
      fieldValues,
      update,
      postAddressRequest,
      putEntryFormRequest,
      updateAnalyticsPage,
      classes,
      isValidEntryForm,
      addressExists,
      setAddressExists,
      questionnaire: { steps, maxStep },
      addressLoading,
    } = this.props;

    const {
      currentAddressSelected,
      currentAddressOwnThisProperty,
      currentAddressMovedInWithin3Months,
      requireSmartMeterInstalled,
      currentAddressLandlineNumber,
      currentAddressIDoNotHaveALandline,
      currentAddressLandlineIsVirgin,
      previousAddressOwnThisProperty,
      previousAddressSelected,
      isFlat,
      metersAccessible,
    } = fieldValues;

    return (
      <>
        <Helmet>
          <title>{EntryFormQuestionnaire.getTitleFromSection(steps)}</title>
        </Helmet>
        <div className={classes.questionnaireContainer}>
          <AddressExistsDialog
            open={addressExists}
            onClose={() => setAddressExists(false)}
          />
          <Intro
            previousPath={YOUR_ADDRESS_PATH}
            update={update}
            addStep={() => this.addStep(LANDLINE_PATH)}
            selectStep={() => this.setStep(0, ADDRESS_GET_STARTED_PATH)}
            steps={steps}
            maxStep={maxStep}
            currentAddressSelected={currentAddressSelected}
            postAddressRequest={postAddressRequest}
            addressNotListed={this.props.addressNotListed}
            updateAnalyticsPage={updateAnalyticsPage}
            addressLoading={addressLoading}
          />
          <Homephone
            addStep={() => this.addStep(PROPERTY_PATH)}
            selectStep={() => this.setStep(1, LANDLINE_PATH)}
            steps={steps}
            maxStep={maxStep}
            removeStep={() => this.removeStep(ADDRESS_GET_STARTED_PATH)}
            currentAddressSelected={currentAddressSelected}
            currentAddressLandlineNumber={currentAddressLandlineNumber}
            currentAddressLandlineIsVirgin={currentAddressLandlineIsVirgin}
            currentAddressIDoNotHaveALandline={
              currentAddressIDoNotHaveALandline
            }
            update={update}
            updateAnalyticsPage={updateAnalyticsPage}
            fieldValues={fieldValues}
          />
          <Property
            addStep={() => this.addStep(ENERGY_PATH)}
            selectStep={() => this.setStep(2, PROPERTY_PATH)}
            steps={steps}
            maxStep={maxStep}
            removeStep={() => this.removeStep(LANDLINE_PATH)}
            currentAddressSelected={currentAddressSelected}
            currentAddressOwnThisProperty={currentAddressOwnThisProperty}
            currentAddressMovedInWithin3Months={
              currentAddressMovedInWithin3Months
            }
            isFlat={isFlat}
            currentAddressLandlineNumber={currentAddressLandlineNumber}
            currentAddressLandlineIsVirgin={currentAddressLandlineIsVirgin}
            putEntryFormRequest={putEntryFormRequest}
            previousAddressOwnThisProperty={previousAddressOwnThisProperty}
            previousAddressSelected={previousAddressSelected}
            update={update}
            requireSmartMeterInstalled={requireSmartMeterInstalled}
          />
          {currentAddressOwnThisProperty === 'no' && isFlat && (
            <Energy
              selectStep={() => this.setStep(3, ENERGY_PATH)}
              steps={steps}
              maxStep={maxStep}
              removeStep={() => this.removeStep(PROPERTY_PATH)}
              putEntryFormRequest={putEntryFormRequest}
              requireSmartMeterInstalled={requireSmartMeterInstalled}
              currentAddressSelected={currentAddressSelected}
              displayBtnDisabled={displayBtnDisabled}
              currentAddressLandlineNumber={currentAddressLandlineNumber}
              currentAddressLandlineIsVirgin={currentAddressLandlineIsVirgin}
              currentAddressOwnThisProperty={currentAddressOwnThisProperty}
              currentAddressMovedInWithin3Months={
                currentAddressMovedInWithin3Months
              }
              previousAddressOwnThisProperty={previousAddressOwnThisProperty}
              previousAddressSelected={previousAddressSelected}
              isFlat={isFlat}
              metersAccessible={metersAccessible}
              update={update}
              isValidEntryForm={isValidEntryForm}
            />
          )}
        </div>
      </>
    );
  }
}

EntryFormQuestionnaire.defaultProps = {
  addressExists: false,
};

EntryFormQuestionnaire.propTypes = {
  update: PropTypes.func.isRequired,
  postAddressRequest: PropTypes.func.isRequired,
  isValidEntryForm: PropTypes.bool.isRequired,
  addressExists: PropTypes.bool,
  fieldValues: PropTypes.object.isRequired,
  updateAnalyticsPage: PropTypes.func.isRequired,
  setAddressExists: PropTypes.func.isRequired,
  questionnaire: PropTypes.shape({
    steps: PropTypes.number.isRequired,
    maxStep: PropTypes.number.isRequired,
  }),
  setStep: PropTypes.func.isRequired,
  addStep: PropTypes.func.isRequired,
  removeStep: PropTypes.func.isRequired,
  putEntryFormRequest: PropTypes.func.isRequired,
  addressNotListed: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  addressLoading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(EntryFormQuestionnaire);
