import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, CircularProgress, InputAdornment } from '@material-ui/core';
import { SearchIcon } from '@utilitywarehouse/partner-ui-icons';
import TextField from 'modules/CustomerUI/TextField';
import useStyles from './styles';

const Select = ({
  options,
  value,
  onChange,
  loading,
  dataTestId,
  label,
  id,
  ...props
}) => {
  const classes = useStyles();

  return (
    <TextField
      {...props}
      textFieldClasses={{ form: classes.textField }}
      select
      fullWidth
      label={label}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      id={id}
      disabled={loading}
      dataTestId={dataTestId}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        ),
        endAdornment: loading && (
          <InputAdornment position="end">
            <CircularProgress
              size={24}
              thickness={5}
              color="secondary"
              className={classes.addressResultsLoading}
            />
          </InputAdornment>
        ),
      }}
    >
      {options?.map((option) => (
        <MenuItem
          value={option.value}
          key={option.label}
          data-test-id={option.label}
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  value: PropTypes.string,
  loading: PropTypes.bool,
  label: PropTypes.string,
  dataTestId: PropTypes.string,
  id: PropTypes.string,
};

export default Select;
