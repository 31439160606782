import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Card } from '@material-ui/core';

import EditButton from '../EditButton';
import useStyles from '../../styles';
import { SpecificItemTypes } from '../../utils/const';

const ItemDisplay = ({ item, handleEdit }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <div className={classes.cardHeading}>
        <Typography variant="h4">{SpecificItemTypes[item.type]}</Typography>
        <EditButton text="Edit" onClick={handleEdit} />
      </div>

      <Typography variant="body1">
        <b>Cost to replace item:</b> £{item.value}
      </Typography>

      <Typography variant="body1">
        <b> Description:</b> {item.description}
      </Typography>

      <Typography variant="body1">
        Cover away from home{' '}
        <b> {item.awayFromHome ? 'required' : 'not required'}</b>
      </Typography>
    </Card>
  );
};

ItemDisplay.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    awayFromHome: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
  }),
  handleEdit: PropTypes.func,
};

export default ItemDisplay;
