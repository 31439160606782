export const SpecificItemTypes = {
  A01: 'Aerials (Less Than 60 Feet High)',
  A04: 'Angling Equipment',
  A20: 'Antique Furniture',
  A21: 'AntiqueRare Books',
  A05: 'Archery Equipment',
  A22: 'Articles Made Of Precious Stones',
  W16: 'Audio',
  B06: 'Business Equipment',
  W17: 'Camcorder',
  C01: 'Camping Equipment',
  C02: 'Car Audio Equipment',
  C03: 'Car Telephones',
  A23: 'China',
  C07: 'China, Glass And Porcelain',
  W20: 'Cleaning',
  C20: 'Clocks',
  C08: 'Clothing',
  C19: 'Coin Collection',
  C22: 'Comic Collections',
  C11: 'Computer Equipment',
  C12: 'Contact Lenses',
  C17: 'Contents In Depository',
  W09: 'Cooker With Hood',
  W12: 'Cooker Without Hood',
  C15: 'Cups, Shields, Trophies And Masonic Regalia',
  C21: 'Curios',
  D01: 'Dentures (Including Crowns)',
  W05: 'Dishwasher',
  E01: 'Electrical Goods',
  W23: 'Entertainment',
  W08: 'Freezer',
  W06: 'Fridge',
  W07: 'Fridge/Freezer',
  F04: 'Furniture',
  F02: 'Furs',
  F03: 'Furs In Cold Storage',
  G06: 'Gemstones',
  A24: 'Glass',
  G07: 'Gold Items',
  G05: 'Gold Silver Plate',
  G11: 'Golf Buggy',
  G02: 'Golf Trolley (Self Propelled)',
  G03: 'Golfing Equipment',
  G04: 'Guns',
  H01: 'Hearing Aids',
  W22: 'Heating',
  W13: 'Hob With Hood',
  W11: 'Hob Without Hood',
  J01: 'Jewellery/Watches',
  W21: 'Kitchen',
  A15: 'Manuscripts',
  M06: 'Medal Collection',
  M12: 'Medical Equipment',
  W18: 'Microwave',
  M03: 'Mobile Phone(s)',
  D03: 'Mobility Scooter/Carriage',
  M01: 'Musical Instruments - Amateur',
  O04: 'Optical Equipment (eg Binoculars, Telescopes, etc)',
  O01: 'Other Collections',
  W10: 'Oven',
  P01: 'Paintings',
  P19: 'Pearls',
  P08: 'Photographic Equipment - Amateur',
  P10: 'Pianos',
  P20: 'Pictures',
  A25: 'Porcelain',
  P23: 'Portable Computers',
  P24: 'Portable Medical Equipment',
  P18: 'Portable TV',
  P11: 'Prams And Pushchairs',
  P12: 'Precious Metals',
  P14: 'Property Lodged In A Bank',
  R03: 'Ride On Lawn Mower',
  R01: 'Riding Tack',
  A16: 'Rugs',
  A17: 'Sculptures',
  S13: 'Silver Items',
  S02: 'Skis (Including Water Skis)',
  S08: 'Sound Equipment',
  S10: 'Spectacles',
  S04: 'Sporting Equipment',
  S05: 'Sporting Guns And Shooting Equipment',
  S11: 'Stamp Collection',
  S06: 'Stamp/Coin Collections',
  A18: 'Statues',
  S16: 'Sunglasses',
  A19: 'Tapestries',
  T01: 'Tools',
  T08: 'Tools used for DIY',
  W04: 'Tumbledryer',
  W15: 'TV',
  T03: 'TVs/Videos/HiFi/Radios',
  W14: 'Vacuum Cleaner',
  V02: 'Valuables, Deeds And Securities Deposited In Bank',
  A14: 'Video Cassette Recorders',
  V03: 'Video Tape Recorders And Video Cameras',
  W03: 'Washer/Dryer',
  W02: 'Washing Machine',
  E03: 'Wheelchairs - Electric',
  W25: 'Wheelchairs - Self Propelled',
  W24: 'Wine Collection',
  W01: 'Works Of Art Etc.',
};
export const ClaimType = {
  '01': 'Accidental damage',
  '42': 'Accidental damage to audio visual equipment',
  '41': 'Accidental loss',
  '35': 'Accidental loss of contents',
  '71': 'Adaption of home following a disability',
  '17': 'Aircraft',
  '18': 'All risks if a type not already listed',
  '02': 'Breakage collapse of TV aerials',
  '14': 'Burst pipes',
  '44': 'Coastal flood',
  '03': 'Collision aircraft vehicles livestock',
  '69': 'Drains and underground tanks',
  '19': 'Earthquake',
  '20': 'Employers liability',
  '04': 'Escape of oil',
  '05': 'Escape of water',
  '21': 'Explosion',
  '22': 'Explosion caused by terrorism',
  '06': 'Falling trees',
  '73': 'Fatal accidents',
  '07': 'Fire',
  '66': 'Fire caused by arson',
  '23': 'Fire caused by terrorism',
  '13': 'Flood',
  '76': 'Flood reinsured with flood re',
  '15': 'Freezer breakdown',
  '24': 'Freezer contents',
  '25': 'Glass mirrors',
  '37': 'Ground heave',
  '08': 'Ground heave subsidence landslide',
  '40': 'Impact',
  '74': 'Jury duty',
  '38': 'Landslide',
  '39': 'Landslip',
  '70': 'Legal expenses',
  '09': 'Liability',
  '16': 'Lightning',
  '68': 'Loss adjuster fees',
  '67': 'Loss adjuster payments',
  '43': 'Loss of currency credit cards',
  '26': 'Malicious damage',
  '10': 'Malicious damage riot',
  '75': 'Personal injury',
  '27': 'Public liability buildings',
  '28': 'Public liability contents',
  '29': 'Removal transit',
  '30': 'Repair of blocked sewer',
  '31': 'Riot civil commotion',
  '45': 'Riverine flood',
  '32': 'Sanitary ware',
  '11': 'Storm damage',
  '36': 'Subsidence',
  '12': 'Theft',
  '65': 'Theft locks and keys',
  '64': 'Theft away from home',
  '72': 'Theft involving forcible entry for use in ROI only',
  '34': 'Theft from car',
  '63': 'Theft from outbuildings',
  '55': 'Travel baggage',
  '46': 'Travel cancellation',
  '47': 'Travel curtailment abandonment',
  '56': 'Travel delayed baggage',
  '53': 'Travel delayed departure',
  '50': 'Travel emergency medical expenses',
  '58': 'Travel hijack',
  '62': 'Travel inability to ski',
  '52': 'Travel legal expenses',
  '51': 'Travel loss of passport',
  '54': 'Travel missed departure',
  '48': 'Travel personal accident',
  '49': 'Travel personal liability',
  '57': 'Travel personal money',
  '61': 'Travel piste closure',
  '59': 'Travel ski equipment',
  '60': 'Travel ski pack',
  '33': 'Underground service pipe',
  '99': 'Not covered by any other item on the list',
};

export const BicycleType = {
  Mountain: 'MOUNTAIN',
  Racing: 'RACING',
  Tandem: 'TANDEM',
  Touring: 'TOURING',
};

export const BicycleTypeArray = Object.keys(BicycleType).map((key) => ({
  value: key,
  label: BicycleType[key],
}));

export const SpecificItemTypesArray = Object.keys(
  SpecificItemTypes
).map((key) => ({ value: key, label: SpecificItemTypes[key] }));

export const ClaimTypesArray = Object.keys(ClaimType).map((key) => ({
  value: key,
  label: ClaimType[key],
}));

export const boolOptions = [
  { value: true, label: 'Yes', testId: 'yes' },
  { value: false, label: 'No', testId: 'no' },
];
