import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@utilitywarehouse/colour-system';

const style = (theme) => ({
  newMainContent: {
    paddingBottom: theme.spacing(6),
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(2)}px`,
    maxWidth: '60%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  textField: { marginTop: theme.spacing(1) },
  header: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: `${theme.spacing(3)}px`,
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      marginBottom: theme.spacing(4),
    },
  },
  headerCopy: {
    flexGrow: 1,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    gap: `${theme.spacing(1)}px`,
    maxWidth: '800px',
  },
  sectionCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: `${theme.spacing(1)}px`,
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.spacing(1),
  },
  selectWrapper: {
    maxWidth: '500px',
    marginTop: 0,
    '& > *': { marginTop: 0 },
  },
  input: {
    marginTop: 0,
  },
  list: {
    padding: 0,
    paddingLeft: theme.spacing(2),
    margin: 0,
    fontSize: '16px',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  linkWrapper: {
    maxWidth: 400,
    '& + &': {
      marginTop: theme.spacing(2),
    },
  },
  link: {
    display: 'flex',
    gap: `${theme.spacing(1)}px`,
  },
  checkIcon: {
    color: colors.green700,
  },
  tile: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5, 2, 0.5, 1),
    margin: 0,
    borderRadius: 10,
    border: `2px solid ${theme.palette.grey[300]}`,
    position: 'relative',
  },
  tileChecked: {
    borderColor: theme.palette.secondary.main,
  },
  optionLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.h6.fontSize,
  },
  optionLabelChecked: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  editSimButton: {
    padding: 0,
    minWidth: 0,
    textDecoration: 'underline',
    textDecorationThickness: '2px',
    textDecorationColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    width: '80px',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationThickness: '2px',
      textDecorationColor: theme.palette.secondary.light,
      color: theme.palette.secondary.light,
    },
  },
  editSimButtonText: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(0.25),
    fontWeight: theme.typography.fontWeightMedium,
    textUnderlinePosition: 'under',
  },
  cardHeading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  serviceList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: { flexDirection: 'column' },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'stretch',
      gap: '16px',
    },
    '& > div': {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  },
});

export default makeStyles(style);
