import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { AddIcon } from '@utilitywarehouse/partner-ui-icons';
import ValuableItemsCard from './ValuableItemsCard';
import useStyles from '../../styles';

const SpecifiedItems = ({
  specifiedItemsList,
  addSpecifiedItem,
  updateSpecifiedItem,
  removeSpecifiedItem,
  requirementType,
}) => {
  const [isNewItem, setNewItem] = useState(false);
  const classes = useStyles();

  const handleCloseNewItem = useCallback(() => setNewItem(false), []);

  return (
    <>
      <Typography variant="h3">Valuable Items</Typography>
      <Typography variant="body1">
        If you require cover for any specific personal possessions within the
        home or away from the home, please add details.
      </Typography>
      {specifiedItemsList?.map((item, index) => (
        <ValuableItemsCard
          key={item.id}
          index={index}
          requirementType={requirementType}
          item={item}
          handleUpdate={updateSpecifiedItem}
          removeSpecifiedItem={removeSpecifiedItem}
          handleCloseNewItem={handleCloseNewItem}
        />
      ))}
      {isNewItem && (
        <ValuableItemsCard
          isNewItem
          requirementType={requirementType}
          handleUpdate={updateSpecifiedItem}
          removeSpecifiedItem={removeSpecifiedItem}
          addSpecifiedItem={addSpecifiedItem}
          handleCloseNewItem={handleCloseNewItem}
        />
      )}
      <Button
        variant="outlined"
        className={classes.select}
        size="medium"
        startIcon={<AddIcon fontSize="small" />}
        onClick={() => setNewItem(true)}
        disabled={isNewItem}
      >
        Add item
      </Button>
    </>
  );
};

SpecifiedItems.propTypes = {
  specifiedItemsList: PropTypes.any,
  addSpecifiedItem: PropTypes.func,
  updateSpecifiedItem: PropTypes.func,
  removeSpecifiedItem: PropTypes.func,
  requirementType: PropTypes.any,
};

export default SpecifiedItems;
