import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { ChevronLeftIcon } from '@utilitywarehouse/partner-ui-icons';
import useStyles from '../styles';

const Header = ({ onBack }) => {
  const classes = useStyles();
  return (
    <header className={classes.header}>
      <Button
        variant="text"
        className={classes.editSimButton}
        onClick={onBack}
        size="large"
      >
        <ChevronLeftIcon />
        <span className={classes.editSimButtonText}>Back</span>
      </Button>
    </header>
  );
};

Header.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default Header;
