import React from 'react';
import PropTypes from 'prop-types';
import createStyles from 'modules/Shared/createJssStyles';
import { APP_URL } from 'config';
import get from 'lodash/get';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import TitleBar from 'components/modules/Shared/TitleBar';
import Button from 'modules/Shared/Button';
import dialogStyles from 'modules/Shared/Dialog/styles';
import media from 'constants/media.js';
import RemotePartnerNotice from 'modules/Remote/PaymentPartnerNotice';

const styles = {
  frame: { width: '100%', height: 300 },
  ...dialogStyles,
  continueWrapper: {
    ...dialogStyles.ContinueHolder,
    paddingRight: '24px !important',
    [media.TABLET]: {
      paddingRight: '8px !important',
      flexDirection: 'column',
    },
  },
  dialogRoot: {
    width: 'unset !important', // makes the dialog use default m-ui width
    [media.TABLET]: {
      margin: '8px !important',
      width: 'calc(100% - 48px) !important', // override paper root with & margin
      maxWidth: 'calc(100% - 48px) !important', // override paper root with & margin
    },
  },
};

const classes = createStyles(styles);

class FingerPrints extends React.Component {
  // catch navigation event to returnURI
  // then navigate to thank you page
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      recoverable: true,
    };

    this.navigateLoad = this.navigateLoad.bind(this);
  }

  navigateLoad() {
    const {
      processPaymentSuccess,
      handleSuccess,
      handleError,
      handleRejected,
    } = this.props;

    const iframe = document.getElementById('verificationFrame');
    let iframeLocation = get(iframe, 'contentWindow.location');
    let href = get(iframeLocation, 'href');
    let parameter = get(iframeLocation, 'search');
    let fullPath = href.replace(parameter, ''); // full path without query params
    let verificationEndPath = `${APP_URL}/checkout/verificationEnd`;

    // only trigger the logic if the url matches end of payment
    if (fullPath === verificationEndPath) {
      if (parameter === '?error=true') {
        // error, show sales contact number
        handleError();
        this.setState({ error: true, recoverable: true });
      }
      if (parameter === '?rejected=true') {
        // error, send to order confirmation
        handleRejected();
        this.setState({ error: true });
        //fire action to handle error and reset general App loading state to allow entering other card details
      } else if (href === verificationEndPath && !parameter) {
        // add config for jtc host
        // send to thank you
        handleSuccess();
        processPaymentSuccess();
      }
    }
  }

  goBack() {
    // trigger action to go back to order confirmation and reset the flow
    this.props.processPaymentFailure();
  }

  render() {
    const { verificationUrl, remote } = this.props;

    if (remote.active && !remote.isMainPeerDriving) {
      return <RemotePartnerNotice />;
    }

    const { error, recoverable } = this.state;
    const PaperProps = {
      classes: { root: classes.dialogRoot },
    };

    return (
      <div>
        {!error && (
          <iframe
            id="verificationFrame"
            src={verificationUrl}
            className={classes.frame}
            onLoad={this.navigateLoad}
          />
        )}

        <Dialog
          open={error}
          PaperProps={PaperProps}
          maxWidth={false}
          fullWidth={true}
        >
          <DialogTitle>
            <TitleBar>We're sorry</TitleBar>
          </DialogTitle>
          <DialogContent>
            <h2 className={classes.title}>
              An error occured while processing your payment.
            </h2>
            {!recoverable && (
              <div>
                <p className={classes.pageText}>
                  Please contact sales on{' '}
                  <a href="tel:0333 777 3 212">0333 777 3 212</a> to complete
                  your order.
                </p>
              </div>
            )}
            {recoverable && (
              <div>
                <p className={classes.pageText}>
                  You can go back to the payment form to try with another card.
                </p>
                <p className={classes.pageText}>
                  If it still does not work please contact sales on{' '}
                  <a href="tel:0333 777 3 212">0333 777 3 212</a> to complete
                  your order.
                </p>
              </div>
            )}
          </DialogContent>
          <DialogActions className={classes.continueWrapper}>
            {recoverable && (
              <Button
                label="Go back to Payment Form"
                dataTestId="GoBackPaymentForm"
                onClick={() => this.goBack()}
              />
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

FingerPrints.defaultProps = {
  handleError: () => {},
  handleRejected: () => {},
  handleSuccess: () => {},
};

FingerPrints.propTypes = {
  verificationUrl: PropTypes.string.isRequired,
  processPaymentSuccess: PropTypes.func.isRequired,
  processPaymentFailure: PropTypes.func.isRequired,
  remote: PropTypes.shape({
    active: PropTypes.boolean,
    isMainPeerDriving: PropTypes.boolean,
  }),
  handleError: PropTypes.func,
  handleRejected: PropTypes.func,
  handleSuccess: PropTypes.func,
};

export default FingerPrints;
