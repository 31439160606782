import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { images } from '@utilitywarehouse/partner-ui-assets';
import SectionCentered from 'modules/layout/SectionCentered';
import IllustrationSpotlight from 'modules/Shared/IllustrationSpotlight';
import Step from './Step';
import styles from './styles';
import {
  DESCRIPTION_STEP_ONE,
  DESCRIPTION_STEP_THREE,
  DESCRIPTION_STEP_TWO,
  LABEL_STEP_ONE,
  LABEL_STEP_THREE,
  LABEL_STEP_TWO,
  TITLE,
  TITLE_STEP_ONE,
  TITLE_STEP_THREE,
  TITLE_STEP_TWO,
} from './copy';

const illustrations = images.illustrations;

const JoiningTheClub = ({ classes }) => (
  <SectionCentered>
    <Typography
      classes={{
        root: classes.content,
      }}
      color="primary"
      component="h2"
      variant="h1"
    >
      {TITLE}
    </Typography>
    <Step
      description={DESCRIPTION_STEP_ONE}
      Image={() => (
        <IllustrationSpotlight>
          <img className={classes.stepOneImage} src={illustrations.i8.filled} />
        </IllustrationSpotlight>
      )}
      label={LABEL_STEP_ONE}
      title={TITLE_STEP_ONE}
    />
    <Step
      alternate
      description={DESCRIPTION_STEP_TWO}
      Image={() => (
        <IllustrationSpotlight borderPosition="topLeft">
          <img
            className={classes.stepTwoImage}
            src={illustrations.i13.filled}
          />
        </IllustrationSpotlight>
      )}
      label={LABEL_STEP_TWO}
      title={TITLE_STEP_TWO}
    />
    <Step
      description={DESCRIPTION_STEP_THREE}
      Image={() => (
        <IllustrationSpotlight>
          <img
            className={classes.stepThreeImage}
            src={illustrations.i1.filled}
          />
        </IllustrationSpotlight>
      )}
      label={LABEL_STEP_THREE}
      title={TITLE_STEP_THREE}
    />
  </SectionCentered>
);

JoiningTheClub.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(JoiningTheClub);
