export default ({ breakpoints, palette, spacing }) => {
  return {
    base: {
      flexDirection: 'column',
      backgroundColor: palette.background.default,
    },
    questionnaireContainer: {
      paddingTop: spacing(1),
      maxWidth: 900,
      [breakpoints.up('md')]: {
        paddingTop: spacing(4),
      },
    },
    bgContainer: {
      backgroundColor: palette.primary.main,
      display: 'flex',
      justifyContent: 'flex-start',
      paddingLeft: spacing(4),
      [breakpoints.down('md')]: {
        padding: spacing(0, 4, 4, 4),
      },
    },
    widthContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: 1240,
      width: '75%',
    },
    titleContainer: {
      color: palette.common.white,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingRight: spacing(4),
    },
    titlePage: {
      color: palette.common.white,
      marginTop: spacing(1),
      marginBottom: spacing(1),
    },
    divider: {
      marginTop: -19,
    },
    section: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      paddingTop: spacing(4),
      [breakpoints.down('md')]: {
        paddingTop: spacing(3),
      },
      [breakpoints.down('sm')]: {
        paddingTop: 15,
      },
    },
    contentContainer: {
      maxWidth: 1100,
      width: '100%',
    },
    title: {
      textAlign: 'left',
      color: palette.primary.main,
      paddingBottom: spacing(2),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
    left: {
      flexGrow: 0,
      width: '100%',
      paddingTop: 0,
      paddingRight: 0,
      marginBottom: 32,
      [breakpoints.down('md')]: {
        paddingTop: 0,
        paddingRight: 0,
      },
    },
    accordionNavigation: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    accordionYesNo: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: 250,
    },
    salesLeadNotice: {
      marginBottom: spacing(3),
    },
  };
};
