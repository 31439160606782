import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  InputAdornment,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { QuestionOutlined } from '@utilitywarehouse/partner-ui-icons';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS, EVENT_TYPE } from 'app/lib/analytics/constants';
import {
  LABEL_AMOUNT,
  LABEL_PROVIDER,
  LABEL_DATE,
  LABEL_DATE_IGNORE,
  COMBINED_MAP,
  serviceLabelMap,
} from 'redux/modules/HomeAudit/constants';
import { getAmountErrors } from 'redux/modules/HomeAudit/validate';
import { normalizeAmountValue } from 'redux/modules/HomeAudit/utils';
import SectionCentered from 'components/modules/layout/SectionCentered';
import useStyles from './styles';

const ProviderInput = ({ service, providers, fieldValue, setFieldValue }) => {
  const classes = useStyles();
  return (
    <Autocomplete
      options={providers}
      onChange={(e, value) => setFieldValue(service, LABEL_PROVIDER, value)}
      value={fieldValue || ''}
      className={classes.input}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`Who is your current ${serviceLabelMap[service]} provider?`}
          variant="filled"
          fullWidth
        />
      )}
    />
  );
};

ProviderInput.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  service: PropTypes.string.isRequired,
  fieldValue: PropTypes.string,
  providers: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const ProviderForm = ({
  service,
  providers,
  fields,
  setFieldValue,
  amountError,
  tooltip,
  collectDate,
}) => {
  const classes = useStyles();
  const analytics = useTracking();
  return (
    <>
      <ProviderInput
        service={service}
        providers={providers}
        fieldValue={fields[LABEL_PROVIDER]}
        setFieldValue={setFieldValue}
      />

      <TextField
        error={Boolean(amountError)}
        label="How much do you pay every month?"
        placeholder="e.g. 123"
        helperText={amountError}
        className={classes.input}
        variant="filled"
        fullWidth
        value={fields[LABEL_AMOUNT] || ''}
        onChange={(e) =>
          setFieldValue(
            service,
            LABEL_AMOUNT,
            normalizeAmountValue(e.target.value)
          )
        }
        InputProps={{
          startAdornment: <InputAdornment position="start">£</InputAdornment>,
          endAdornment: (
            <>
              {tooltip.length > 0 && (
                <Tooltip title={tooltip}>
                  <InputAdornment position="end">
                    <QuestionOutlined color="primary" />
                  </InputAdornment>
                </Tooltip>
              )}
            </>
          ),
        }}
      />

      {collectDate && (
        <div className={classes.inputGroup}>
          <TextField
            label="When is your current contract ending?"
            placeholder="dd/mm/yyyy"
            className={classes.input}
            variant="filled"
            type="date"
            fullWidth
            disabled={fields[LABEL_DATE_IGNORE]}
            value={fields[LABEL_DATE] || ''}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              analytics.track(EVENTS.HOME_AUDIT_DATE_INPUT_CHANGED, {
                event_type: EVENT_TYPE.BEHAVIOUR,
                service,
              });
              setFieldValue(service, LABEL_DATE, e.target.value);
            }}
          />
          <FormControlLabel
            label="I'm not in a contract"
            classes={{ root: classes.inputToggle }}
            value={fields[LABEL_DATE_IGNORE]}
            control={
              <Checkbox
                checked={fields[LABEL_DATE_IGNORE]}
                onChange={(e) =>
                  setFieldValue(service, LABEL_DATE_IGNORE, e.target.checked)
                }
              />
            }
          />
        </div>
      )}
    </>
  );
};

ProviderForm.propTypes = {
  service: PropTypes.string.isRequired,
  providers: PropTypes.arrayOf(PropTypes.string).isRequired,
  fields: PropTypes.shape({
    [LABEL_AMOUNT]: PropTypes.string,
    [LABEL_PROVIDER]: PropTypes.string,
  }),
  setFieldValue: PropTypes.func.isRequired,
  amountError: PropTypes.string,
  tooltip: PropTypes.string,
  collectDate: PropTypes.bool,
};

const CollectionForm = ({
  setFieldValue,
  setCombinedServices,
  fetchProviders,
  service,
  combinedServices,
  providers,
  fieldValues,
  tooltip = '',
  collectDate = false,
  collectionFormClasses = {},
}) => {
  const classes = useStyles();
  useEffect(() => {
    if (!combinedServices && COMBINED_MAP[service]) {
      COMBINED_MAP[service].forEach((s) => fetchProviders(s));
    } else {
      fetchProviders(service);
    }
  }, [service, combinedServices, fetchProviders]);

  const fields = fieldValues[service] || {
    [LABEL_PROVIDER]: '',
    [LABEL_AMOUNT]: '',
  };

  const amountError = getAmountErrors(fields[LABEL_AMOUNT]);
  const splitServices = !!COMBINED_MAP[service];
  return (
    <SectionCentered classes={{ root: collectionFormClasses.root }}>
      <div className={classes.root}>
        <div className={classes.form}>
          <Typography variant="h3" component="h2" color="primary">
            Tell us about your current provider
          </Typography>

          {splitServices && (
            <FormControlLabel
              label="My providers are the same"
              control={
                <Checkbox
                  checked={combinedServices}
                  onChange={() => setCombinedServices(!combinedServices)}
                />
              }
            />
          )}
          {!!COMBINED_MAP[service] &&
            !combinedServices &&
            COMBINED_MAP[service].map((s) => (
              <ProviderForm
                key={s}
                service={s}
                providers={providers[s]}
                fields={fieldValues[s]}
                setFieldValue={setFieldValue}
                tooltip={tooltip}
                amountError={amountError}
                collectDate={collectDate}
              />
            ))}

          {(!COMBINED_MAP[service] || combinedServices) && (
            <ProviderForm
              service={service}
              providers={providers[service]}
              fields={fields}
              setFieldValue={setFieldValue}
              tooltip={tooltip}
              amountError={amountError}
              collectDate={collectDate}
            />
          )}
        </div>
        <div className={classes.image}>
          <img src="images/boilercover/thinking.svg" alt="" />
        </div>
      </div>
    </SectionCentered>
  );
};

CollectionForm.propTypes = {
  fetchProviders: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  service: PropTypes.string.isRequired,
  combinedServices: PropTypes.bool.isRequired,
  providers: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  fieldValues: PropTypes.objectOf(
    PropTypes.shape({
      [LABEL_AMOUNT]: PropTypes.string,
      [LABEL_PROVIDER]: PropTypes.string,
    })
  ).isRequired,
  setCombinedServices: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  collectDate: PropTypes.bool,
  collectionFormClasses: PropTypes.shape({ root: PropTypes.string }),
};
export default CollectionForm;
