import React, { useState } from 'react';

import { Typography, Button, Card } from '@material-ui/core';
import { ChevronLeftIcon } from '@utilitywarehouse/partner-ui-icons';

import SectionCentered from 'modules/layout/SectionCentered';

import useStyles from '../styles';
import Select from '../components/Select';
import { useNavigation } from '../context/navigation';

//TODO: Move this out to const
const propertyTypes = [
  { value: 'house', label: 'House' },
  { value: 'flat', label: 'Flat' },
  { value: 'bungalow', label: 'Bungalow' },
  { value: 'other', label: 'Other' },
];
const numRooms = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: 'more', label: '7+' },
];

const propertyValue = [
  { value: '100000', label: '£100000' },
  { value: '200000', label: '£200000' },
  { value: '300000', label: '£300000' },
  { value: '400000', label: '£400000' },
  { value: '500000', label: '£500000' },
];

const listingOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const wallsConstruction = [
  { value: 'brick', label: 'Brick' },
  { value: 'concrete', label: 'Concrete' },
  { value: 'stone', label: 'Stone' },
  { value: 'other', label: 'Other' },
];
const roofConstruction = [
  { value: 'tile', label: 'Tile' },
  { value: 'slate_roof', label: 'Slate Roof' },
  { value: 'concrete', label: 'Concrete' },
  { value: 'other', label: 'Other' },
];

const AboutProperty = () => {
  const classes = useStyles();
  const { backToQuote } = useNavigation();

  // TODO: TEST STATE
  const [state, setState] = useState({
    propertyType: propertyTypes[0].value,
    value: propertyValue[3].value,
    numOfBedrooms: numRooms[0].value,
    numOfBathrooms: numRooms[0].value,
    numOfOtherRooms: numRooms[0].value,
    madeOf: wallsConstruction[0].value,
    roofMadeOf: roofConstruction[0].value,
    isListed: false,
  });

  const updateState = (key, value) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };
  return (
    <>
      <SectionCentered
        backgroundColor="grey"
        newLayout
        sectionCenteredClasses={{ root: classes.newMainContent }}
      >
        <header className={classes.header}>
          <Button
            variant={'text'}
            className={classes.editSimButton}
            onClick={backToQuote}
            size={'large'}
          >
            <ChevronLeftIcon />
            <span className={classes.editSimButtonText}>Back</span>
          </Button>
        </header>
        <main className={classes.mainContent} style={{ maxWidth: '80%' }}>
          <Card className={classes.card}>
            <Typography variant="h3">About your property</Typography>
            <Select
              value={state.propertyType}
              onChange={(value) => updateState('propertyType', value)}
              options={propertyTypes}
              label="What type of property do you have?"
            />
            <Select
              value={state.value}
              onChange={(value) => updateState('value', value)}
              options={propertyValue}
              label="What is the approximate value of your property?"
            />
            <Select
              value={state.numOfBedrooms}
              onChange={(value) => updateState('numOfBedrooms', value)}
              options={numRooms}
              label="How many bedrooms does your property have?"
            />
            <Select
              value={state.numOfBathrooms}
              onChange={(value) => updateState('numOfBathrooms', value)}
              options={numRooms}
              label="How many bathrooms does your property have?"
            />
            <Select
              value={state.numOfOtherRooms}
              onChange={(value) => updateState('numOfOtherRooms', value)}
              options={numRooms}
              label="Are there any other rooms in your house?"
            />
            <Select
              value={state.madeOf}
              onChange={(value) => updateState('madeOf', value)}
              options={wallsConstruction}
              label="What is your property made of?"
            />
            <Select
              value={state.roofMadeOf}
              onChange={(value) => updateState('roofMadeOf', value)}
              options={roofConstruction}
              label="What is your roof made of?"
            />
            <Select
              value={state.isListed}
              onChange={(value) => updateState('isListed', value)}
              options={listingOptions}
              label="Is the property listed?"
            />
          </Card>
        </main>
      </SectionCentered>
    </>
  );
};
AboutProperty.propTypes = {};

export default AboutProperty;
