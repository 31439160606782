import { makeStyles } from '@material-ui/core';

export default makeStyles(({ breakpoints, spacing }) => ({
  root: {
    backgroundColor: 'white',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  imageContainer: {
    margin: spacing(2),
  },
  button: {
    marginTop: spacing(2),
    width: '100%',
    [breakpoints.up('sm')]: {
      minWidth: 64,
      width: 'auto',
    },
  },
}));
