import React from 'react';
import PropTypes from 'prop-types';

import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles(({ typography, palette, spacing }) => ({
  expansionPanelRoot: {
    border: 'none',
    padding: 0,
    margin: 0,
  },
  expansionContainer: {
    margin: 0,
    padding: 0,
    minHeight: '24px',
    '& .MuiExpansionPanelSummary-content': {
      margin: 0,
    },
  },
  title: {
    ...typography.body1,
    color: 'black',
    margin: 0,
    fontWeight: 600,
  },
  detailsConatiner: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,

    '& > *:last-child': {
      borderBottom: 'unset',
      paddingBottom: 0,
    },
  },
  details: {
    borderBottom: `2px solid ${fade(palette.primary.main, 0.2)}`,
    display: 'flex',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },
  doneIcon: {
    marginRight: spacing(2),
  },
  bold: {
    fontWeight: 'bold',
  },
  rowBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    alignItems: 'flex-end',
  },
}));

const ExpansionRow = ({
  escapeOfWaterExcess,
  standardExcess,
  subsidenceExcess,
}) => {
  const classes = useStyles();

  return (
    <ExpansionPanel classes={{ root: classes.expansionPanelRoot }}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.expansionContainer}
      >
        <Typography color="primary" className={classes.title}>
          Show excess breakdown
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.detailsConatiner}>
        <Box className={classes.rowBox}>
          <Typography className={classes.bold}>Escape of water</Typography>
          <Typography>£{escapeOfWaterExcess}</Typography>
        </Box>
        <Box className={classes.rowBox}>
          <Typography className={classes.bold}>Standart</Typography>
          <Typography>£{standardExcess}</Typography>
        </Box>
        <Box className={classes.rowBox}>
          <Typography className={classes.bold}>Subsidence</Typography>
          <Typography>£{subsidenceExcess}</Typography>
        </Box>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

ExpansionRow.propTypes = {
  escapeOfWaterExcess: PropTypes.string,
  standardExcess: PropTypes.string,
  subsidenceExcess: PropTypes.string,
};

export default ExpansionRow;
