import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Typography, Card } from '@material-ui/core';

import EditButton from '../EditButton';
import useStyles from '../../styles';
import { ClaimType } from '../../utils/const';

const DATE_FORMAT = 'DD MMM YYYY';

function formatDate(date) {
  return moment(date).format(DATE_FORMAT);
}

const occupyCopy =
  'You were living at the property to be insured when you made this claim.';
const notOccupyCopy =
  'You were not living at the property to be insured when you made this claim.';
const settled = 'settled';
const ongoing = 'ongoing';

const ItemDisplay = ({ item, handleEdit }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <div className={classes.cardHeading}>
        <Typography variant="h4">
          {item.date.seconds
            ? formatDate(new Date(item.date.seconds * 1000))
            : formatDate(item.date)}
        </Typography>
        <EditButton text="Edit" onClick={handleEdit} />
      </div>

      <Typography variant="body1">
        Type of claim: <b>{ClaimType[item.type]}</b>
      </Typography>

      <Typography variant="body1">
        Your claim amount is <b>£{item.amount}</b>
      </Typography>
      <Typography variant="body1">
        Claim status: <b>{item.settled ? settled : ongoing}</b>
      </Typography>
      <Typography variant="body1">
        {item.livingInProperty ? occupyCopy : notOccupyCopy}
      </Typography>
    </Card>
  );
};

ItemDisplay.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string.isRequired,
    cover: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    date: PropTypes.date,
    livingInProperty: PropTypes.bool,
    settled: PropTypes.bool,
  }),
  handleEdit: PropTypes.func,
};

export default ItemDisplay;
