import React from 'react';
import PropTypes from 'prop-types';

import Sentry from 'app/lib/analytics/sentry';
import PageTemplate from 'modules/layout/PageTemplate';
import ErrorPage from '../ErrorPage';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    Sentry.log(error, { extra: { info } });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return (
      <>
        {hasError && (
          <PageTemplate helmetTitle="Error">
            <ErrorPage />
          </PageTemplate>
        )}
        {!hasError && children}
      </>
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
