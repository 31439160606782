import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Typography, Button } from '@material-ui/core';
import { ChevronLeftIcon } from '@utilitywarehouse/partner-ui-icons';

import SectionCentered from 'modules/layout/SectionCentered';

import useStyles from '../styles';
import { useNavigation } from '../context/navigation';
import ServiceCard from '../components/ServiceCard';
import ChoiceUpdate from '../components/Choice';

const additionalServices = [
  {
    id: 'accidentalDamageBuildings',
    title: 'Building Accidental Damage',
    price: '£9.99',
    description:
      'Extra cover against mishaps and accidential damage to your home.',
    covered: [
      'Spills acros your carpet',
      'Dropping your tablet or laptop',
      'A smashed ornament or vase',
    ],
    notCovered: [
      'Deliberate acts of damage',
      'Damage caused by pets',
      'Things taken outside the home',
    ],
  },
  {
    id: 'accidentalDamageContents',
    title: 'Contents Accidental Damage',
    price: '£11.50',
    description:
      'Extra cover against mishaps and accidential damage to your belongings.',
    covered: [
      'Spills acros your carpet',
      'Dropping your tablet or laptop',
      'A smashed ornament or vase',
    ],
    notCovered: [
      'Deliberate acts of damage',
      'Damage caused by pets',
      'Things taken outside the home',
    ],
  },
];

const AdditionalCover = ({ quote, updateQuoteField, updateHomeQuote }) => {
  const classes = useStyles();
  const { backToQuote } = useNavigation();
  const { requirements } = quote || {};
  const {
    requirementType,
    requirement: { buildingsAccidentalDamage, contentsAccidentalDamage },
  } = requirements[0];

  // Initial selected services based on props
  const initialServices = [];
  if (buildingsAccidentalDamage) {
    initialServices.push('accidentalDamageBuildings');
  }
  if (contentsAccidentalDamage) {
    initialServices.push('accidentalDamageContents');
  }

  const [selectedServices, setSelectedServices] = useState(initialServices);

  // Update Redux when selectedServices changes
  useEffect(() => {
    updateQuoteField(
      requirementType,
      ['buildingsAccidentalDamage'],
      selectedServices.includes('accidentalDamageBuildings')
    );
    updateQuoteField(
      requirementType,
      ['contentsAccidentalDamage'],
      selectedServices.includes('accidentalDamageContents')
    );
  }, [selectedServices, updateQuoteField]);

  // Check if a service is selected
  const isSelected = (id) => selectedServices.includes(id);

  // Handle adding/removing a service
  const handleAddService = (id) => {
    setSelectedServices((prev) =>
      isSelected(id) ? prev.filter((service) => service !== id) : [...prev, id]
    );
  };

  return (
    <>
      <SectionCentered
        backgroundColor="grey"
        newLayout
        sectionCenteredClasses={{ root: classes.newMainContent }}
      >
        <header className={classes.header}>
          <Button
            variant={'text'}
            className={classes.editSimButton}
            onClick={backToQuote}
            size={'large'}
          >
            <ChevronLeftIcon />
            <span className={classes.editSimButtonText}>Back</span>
          </Button>
        </header>
        <main className={classes.mainContent} style={{ maxWidth: '80%' }}>
          <Typography variant="h2">Additional Cover</Typography>
          <div className={classes.serviceList}>
            {additionalServices.map((service) => (
              <ServiceCard
                service={service}
                key={service.id}
                onSelect={() => handleAddService(service.id)}
                selected={isSelected(service.id)}
              />
            ))}
          </div>
        </main>
      </SectionCentered>
      <ChoiceUpdate onQuoteUpdte={updateHomeQuote} />
    </>
  );
};
AdditionalCover.propTypes = {
  quote: PropTypes.any,
  updateQuoteField: PropTypes.func,
  updateHomeQuote: PropTypes.func,
};

export default AdditionalCover;
