import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  root: {
    display: 'flex',
    columnGap: `${theme.spacing(3)}px`,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 3,
    flexBasis: '50%',
    gap: `${theme.spacing(3)}px`,

    [theme.breakpoints.up(768)]: {
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
  inputGroup: {
    width: '100%',
  },
  input: {
    width: '100%',
    [theme.breakpoints.up(768)]: {
      maxWidth: 500,
    },
  },
  inputToggle: {
    marginLeft: theme.spacing(-1),
  },
  image: {
    display: 'none',
    flexGrow: 1,
    justifyContent: 'center',

    '& > img': {
      width: '100%',
      maxWidth: 400,
      objectFit: 'contain',
    },

    [theme.breakpoints.up(768)]: {
      flexBasis: '50%',
      display: 'flex',
    },
  },
});

export default makeStyles(style);
