export const TITLE = 'Do you have home insurance?';

export const DESCRIPTION =
  "We've recently launched our Home Insurance service. Let us know when your current policy expires and we'll be in touch a few weeks before your policy renews.";

export const THANKS =
  "Thank you for submitting your home insurance renewal date. We'll send you an email around the time you're due to renew.";

export const ERROR =
  "Something went wrong submitting your renewal date but don't worry you can submit it later via Clubhouse.";

export const UNABLE_TO_DETERMINE_ELIGIBILITY =
  "We're currently unable to collect your home insurance renewal date, but don't worry, you can still submit it later via the Clubhouse.";
