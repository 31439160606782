import gstyles from 'constants/styles.js';
import colors from 'constants/colors.js';

export default {
  dialogTitle: {
    color: colors.secondary.darkGrey,
    ...gstyles.fonts.h3,
  },
  dialogText: {
    color: colors.secondary.darkGrey,
    ...gstyles.fonts.regular,
    ...gstyles.fonts.p5,
    textAlign: 'center',
  },
  loaderDiv: {
    margin: '0 auto',
    width: 80,
  },
};
