import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';

import ErrorMessage from 'modules/Shared/ErrorMessage';
import TitleBar from 'modules/Shared/TitleBar';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';
import Space from 'components/modules/Shared/Space';
import TextField from 'components/modules/Shared/NewTextField';
import validateGBNumber from 'redux/utils/validateGBNumber.js';
import YesNoButtonCombo from '../../YesNoButtonCombo';
import styles from './styles';

const Homephone = ({
  steps,
  update,
  maxStep,
  addStep,
  selectStep,
  removeStep,
  fieldValues,
  currentAddressSelected,
  currentAddressLandlineNumber,
  currentAddressIDoNotHaveALandline,
  classes,
}) => {
  const errorMessage =
    validateGBNumber(currentAddressLandlineNumber) === false
      ? 'Please enter a valid number.'
      : '';

  const hasValidLandline =
    validateGBNumber(currentAddressLandlineNumber) === true;
  const verifyIfLandlineIsVirgin =
    fieldValues.currentAddressLandlineIsVirgin !== undefined &&
    fieldValues.currentAddressLandlineIsVirgin !== '';

  const isValidForm =
    (hasValidLandline && verifyIfLandlineIsVirgin) ||
    currentAddressIDoNotHaveALandline === true;

  return (
    <Fragment>
      {steps !== 1 && (
        <TitleBar variant="h4" complete={maxStep > 1} onClick={selectStep}>
          Landline
        </TitleBar>
      )}
      {currentAddressSelected && steps === 1 && (
        <Paper
          variant="outlined"
          classes={{
            root: classes.container,
          }}
        >
          <Typography variant="h4" component="h4" className={classes.title}>
            Landline
          </Typography>
          <div className={classes.landlineFields}>
            <TextField
              label="Landline number"
              margin="none"
              fullWidth={false}
              type="tel"
              value={currentAddressLandlineNumber || ''}
              onChange={(_, value) => {
                update(value, 'currentAddressLandlineNumber');
                update(false, 'currentAddressIDoNotHaveALandline');
              }}
              dataTestId="LandingPageTextFieldLandline"
              autoFocus
              textFieldClasses={{ form: classes.textField }}
            />
            <FormControlLabel
              label={
                <Typography variant="body2" component="p">
                  I don't have a landline number
                </Typography>
              }
              checked={currentAddressIDoNotHaveALandline || false}
              onChange={() => {
                update(true, 'currentAddressIDoNotHaveALandline');
                update('', 'currentAddressLandlineNumber');
                update('', 'currentAddressLandlineIsVirgin');
              }}
              id="LandingPageCheckboxNoLandline"
              data-test-id="LandingPageCheckboxNoLandline"
              control={<Checkbox />}
              classes={{ root: classes.noLandline }}
            />
          </div>
          {!isEmpty(errorMessage) && <ErrorMessage message={errorMessage} />}
          {!!currentAddressLandlineNumber && (
            <Fragment>
              <Space height="16px" />
              <Typography variant="body1" component="p">
                Is that a Virgin landline?
              </Typography>
              <YesNoButtonCombo
                expectedYesValue
                expectedNoValue={false}
                onYes={() => update(true, 'currentAddressLandlineIsVirgin')}
                onNo={() => update(false, 'currentAddressLandlineIsVirgin')}
                valueSelected={fieldValues.currentAddressLandlineIsVirgin}
                yesDataTestId="LandingPageVirginLineYes"
                noDataTestId="LandingPageVirginLineNo"
              />
            </Fragment>
          )}
          {currentAddressIDoNotHaveALandline === true && (
            <Alert severity="warning" variant="filled">
              Important: Before selecting 'No' (above) please be absolutely
              certain that there isn't a landline number connected to this
              address. Otherwise, a second phone number will be allocated to you
              and you may have to pay an additional charge.
            </Alert>
          )}
          <Space height="16px" />
          <div className={classes.accordionNavigation}>
            <RestrictedButton
              size="large"
              variant="outlined"
              label="Previous"
              onClick={() => removeStep()}
              dataTestId="LandingPageLandlinePrevious"
            />
            <RestrictedButton
              size="large"
              variant="contained"
              label="Next"
              onClick={() => addStep()}
              disabled={isValidForm === false}
              dataTestId="LandingPageLandlineNext"
            />
          </div>
        </Paper>
      )}
    </Fragment>
  );
};

Homephone.propTypes = {
  currentAddressLandlineNumber: PropTypes.string,
  currentAddressIDoNotHaveALandline: PropTypes.bool,
  currentAddressSelected: PropTypes.object,
  fieldValues: PropTypes.shape({
    currentAddressLandlineIsVirgin: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
  }),
  removeStep: PropTypes.func,
  addStep: PropTypes.func,
  steps: PropTypes.number.isRequired,
  maxStep: PropTypes.number.isRequired,
  selectStep: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Homephone);
