import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';

class NavigatorOnlineError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      online: true,
    };
  }

  setOnline(online) {
    this.setState({
      online,
    });
  }

  componentDidMount() {
    this.setState({
      online: window.navigator.onLine,
    });
    window.addEventListener('offline', this.setOnline.bind(this, false));
    window.addEventListener('online', this.setOnline.bind(this, true));
  }

  render() {
    return (
      <Dialog open={!this.state.online}>
        <DialogTitle>
          <span style={styles.dialogTitle}>Lost internet connection</span>
        </DialogTitle>
        <DialogContent>
          <div style={styles.loaderDiv}>
            <CircularProgress size={80} thickness={3} />
          </div>
          <p style={styles.dialogText}>
            It appears you are offline, please check your internet connection...
          </p>
        </DialogContent>
      </Dialog>
    );
  }
}
export default NavigatorOnlineError;
