import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Card } from '@material-ui/core';

import useStyles from '../../../styles';
import ItemEditForm from './Form';
import ItemDisplay from './ItemDisplay';

const newItem = {
  type: '',
  value: 0,
  description: '',
};

const useItemState = (initialItem, isNewItem) => {
  const [itemState, setItemState] = useState(initialItem);
  useEffect(() => {
    if (isNewItem) {
      setItemState(newItem);
    }
  }, [isNewItem]);
  return [itemState, setItemState];
};

const ValuableItemsCard = ({
  item,
  index,
  requirementType,
  handleUpdate,
  removeBicycleItem,
  addBicycleItem,
  handleCloseNewItem,
  isNewItem = false,
}) => {
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = useState(false);
  const [itemState, setItemState] = useItemState(item || newItem, isNewItem);

  const handleEdit = () => setIsEditMode(true);

  const handleUpdateItem = (key, value) =>
    setItemState((prev) => ({ ...prev, [key]: value }));

  const handleSaveItem = () => {
    isNewItem
      ? addBicycleItem(requirementType, itemState)
      : handleUpdate(requirementType, index, itemState);
    handleCloseNewItem();
    setIsEditMode(false);
  };

  const handleRemove = () => {
    !isNewItem && removeBicycleItem(requirementType, index);
    handleCloseNewItem();
    setIsEditMode(false);
  };

  if (isEditMode || isNewItem) {
    return (
      <Card className={classes.card}>
        <ItemEditForm
          itemState={itemState}
          onItemChange={handleUpdateItem}
          onSave={handleSaveItem}
          onRemove={handleRemove}
        />
      </Card>
    );
  }

  return <ItemDisplay item={itemState} handleEdit={handleEdit} />;
};

ValuableItemsCard.propTypes = {
  requirementType: PropTypes.any,
  index: PropTypes.number,
  item: PropTypes.shape({
    type: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
  }),
  handleUpdate: PropTypes.func,
  addBicycleItem: PropTypes.func,
  handleCloseNewItem: PropTypes.func,
  isNewItem: PropTypes.bool,
  removeBicycleItem: PropTypes.func,
};

export default ValuableItemsCard;
