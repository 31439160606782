import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';

import TitleBar from 'modules/Shared/TitleBar';

const styles = (theme) => ({
  header: {
    padding: theme.spacing(1),
  },
});

const AddressExistsDialog = ({ open, onClose, classes }) => (
  <Dialog open={open}>
    <DialogTitle>
      <TitleBar classNames={{ root: classes.header }}>Your Address</TitleBar>
    </DialogTitle>
    <DialogContent>
      <Typography variant="h4">
        It looks like we already supply this address
      </Typography>
      <Typography variant="body1">
        If you have recently moved into this property, please call the Home
        Movers team on <a href="tel:03337770888">0333 777 0 888</a>.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button
        size="large"
        variant="contained"
        data-test-id="CloseAddressAlreadyRegistered"
        onClick={onClose}
      >
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

AddressExistsDialog.defaultProps = {
  open: false,
};

AddressExistsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddressExistsDialog);
