import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  card: {
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: `${theme.spacing(2)}px`,
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.spacing(1),
  },
});

export default makeStyles(style);
